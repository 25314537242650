var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 5 } }, [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c(
                  "el-scrollbar",
                  [
                    _c(
                      "basic-container",
                      [
                        _c("avue-tree", {
                          attrs: { option: _vm.treeOption, data: _vm.treeData },
                          on: { "node-click": _vm.nodeClick }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "el-col",
            { attrs: { span: 19 } },
            [
              _c(
                "basic-container",
                [
                  _c(
                    "avue-crud",
                    {
                      ref: "crud",
                      attrs: {
                        option: _vm.option,
                        "table-loading": _vm.loading,
                        data: _vm.data,
                        page: _vm.page
                      },
                      on: {
                        "update:page": function($event) {
                          _vm.page = $event
                        },
                        "search-change": _vm.searchChange,
                        "search-reset": _vm.searchReset,
                        "selection-change": _vm.selectionChange,
                        "current-change": _vm.currentChange,
                        "size-change": _vm.sizeChange,
                        "refresh-change": _vm.refreshChange,
                        "on-load": _vm.onLoad
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "avatar",
                          fn: function(scope) {
                            return [
                              scope.row.avatar
                                ? _c(
                                    "div",
                                    { staticClass: "imgs-wrap" },
                                    [
                                      _c("el-image", {
                                        key: scope.row.avatar,
                                        attrs: {
                                          src: scope.row.avatar,
                                          "preview-src-list": [scope.row.avatar]
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ]),
                      model: {
                        value: _vm.form,
                        callback: function($$v) {
                          _vm.form = $$v
                        },
                        expression: "form"
                      }
                    },
                    [
                      _c("div", {
                        attrs: { slot: "menuLeft" },
                        slot: "menuLeft"
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择发言人",
            visible: _vm.dialogVisible,
            width: "30%",
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-checkbox",
            {
              attrs: { indeterminate: _vm.isIndeterminate },
              on: { change: _vm.handleCheckAllChange },
              model: {
                value: _vm.checkAll,
                callback: function($$v) {
                  _vm.checkAll = $$v
                },
                expression: "checkAll"
              }
            },
            [_vm._v("全选\n    ")]
          ),
          _c("div", { staticStyle: { margin: "15px 0" } }),
          _c(
            "el-checkbox-group",
            {
              on: { change: _vm.handleCheckedCitiesChange },
              model: {
                value: _vm.checkedCities,
                callback: function($$v) {
                  _vm.checkedCities = $$v
                },
                expression: "checkedCities"
              }
            },
            _vm._l(_vm.nameList, function(item) {
              return _c("el-checkbox", { key: item, attrs: { label: item } }, [
                _vm._v(_vm._s(item.name) + "\n      ")
              ])
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }