<!--suppress ALL -->
<template>
  <div class="container" style="width: 100%" v-loading="loading">
    <div class="handleBox">
      <div class="inputBox">
        <div class="inputItem">
          <span>部门：</span>
          <el-input v-model="queryValue.activityName" maxlength="24" placeholder="请输入部门名称"></el-input>
        </div>
        <div class="inputItem">
          <span>负责人：</span>
          <el-input v-model="queryValue.createUser" maxlength="24" placeholder="请输入负责人名称"></el-input>
        </div>
        <div class="inputItem">
          <el-button type="primary" @click="searchOne" style="margin-left: 40px;">查询</el-button>
          <el-button type="primary" @click="addOption" style="margin-left: 40px;">新增</el-button>
        </div>
      </div>
    </div>
    <table-area
      :tableHeader="tableHeader"
      :tableData="tableData"
      :areaOpt="true"
      :page-setting="{
          total: this.total,
          pageSize: this.queryParams.pageSize,
          current: this.queryParams.current,
        }"
      @pageChange="pageChange"
      @sizeChange="sizeChange">
      <template slot="option" slot-scope="scope">
        <el-button type="text" size="small" @click="updateOption(scope.data)">修改</el-button>
        <el-button type="text" size="small" @click="statusOption(scope.data)">{{ scope.data.row.status === 0 ? '停用' : '启用' }}</el-button>
        <el-button type="text" size="small" @click="deleteOption(scope.data)">删除</el-button>
      </template>
    </table-area>
    <el-dialog :title="isDialogAdd?'新增':'修改'" :visible.sync="showDialog" :modal-append-to-body="false" width="40%">
      <div class="dialogBox">
        <div class="boxItem">
          <p><b>*</b>所属上级：</p>
          <el-input v-model="form.activityName" placeholder="请输入所属上级" maxlength="24" style="width: 75%"></el-input>
        </div>
        <div class="boxItem">
          <p><b>*</b>组织名称：</p>
          <el-input v-model="form.activityName" placeholder="请输入组织名称" maxlength="24" style="width: 75%"></el-input>
        </div>
        <div class="boxItem">
          <p><b>*</b>负责人：</p>
          <el-input v-model="form.posterAddress" placeholder="请输入负责人" style="width: 75%"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="changeData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tableArea from '@/components/table'
import { posterList, posterAdd, posterUpdate, posterRemove } from '@/api/poster'

export default {
  name: 'organization',
  inject: ['organization'],
  components: {
    tableArea
  },
  data () {
    return {
      tableData: [],
      hasNextPage: true,
      total: 0,
      showDialog: false,
      isDialogAdd: false,
      tableHeader: [
        {
          prop: 'activityName',
          label: '部门名称',
        },
        {
          prop: 'src',
          label: '上级组织',
          width: 250,
        },
        {
          prop: '负责人',
          label: '负责人',
        },
        {
          prop: 'createUser',
          label: '创建人',
          width: 120
        },
        {
          prop: 'Status',
          label: '状态',
          width: 120
        },
        {
          prop: 'createTime',
          label: '创建时间',
          width: 200
        },
        {
          prop: 'option',
          label: '操作',
          width: 250
        }
      ],
      queryValue: {
        activityName: '',
        createUser: '',
        status: ''
      },
      queryParams: {
        activityName: '',
        createUser: '',
        status: ''
      },
      form: {
        activityName: '',
        createUser: '白夜',
        createUserId: '1',
        status: 1,
        posterAddress: ''
      },
      loading: false,
    }
  },
  mounted () {
    // this.searchOne()
  },
  methods: {
    async getData () {
      this.loading = true
      this.showDialog = false
      let params = this.computeParams()
      if (params) {
        let { data } = await posterList(params)
        this.handleData(data)
      } else {
        this.loading = false
      }
    },
    computeParams () {
      let regContent = /^[\u4e00-\u9fa5a-z0-9]+$/i
      if (this.queryValue.activityName) {
        if (regContent.test(this.queryValue.activityName)) {
          this.queryParams.activityName = this.queryValue.activityName
        } else {
          this.$message.error('部门名称只能由中文、字母、数字组成')
          return false
        }
      }
      if (this.queryValue.activityName) {
        if (regContent.test(this.queryValue.activityName)) {
          this.queryParams.activityName = this.queryValue.activityName
        } else {
          this.$message.error('负责人名称只能由中文、字母、数字组成')
          return false
        }
      } else {
        delete this.queryParams.activityName
      }
      return this.queryParams
    },
    handleData ({ data }) {
      this.total = data.total
      this.tableData = data.records.map(item => {
        return {
          ...item,
          Status: item.status === 0 ? '正常' : '停用'
        }
      })
      this.loading = false
    },
    addOption () {
      this.showDialog = true
      this.isDialogAdd = true
      this.form.activityName = ''
      this.form.activityName = ''
      this.form.posterAddress = ''
    },
    updateOption ({ row }) {
      this.showDialog = true
      this.isDialogAdd = false
      this.form = {
        ...row
      }
    },
    async statusOption ({ row }) {
      let res
      if (row.status === 0) {
        res = await this.$confirm('确定停用该部门吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
      }
      if (!res || res === 'confirm') {
        let params = { id: row.id, status: row.status ? '0' : '1' }
        let { data } = await posterUpdate(params)
        if (data.code === 200) {
          this.$message.success('修改成功!')
          await this.getData()
        } else {
          this.$message.error('修改失败')
        }
      } else if (res === 'cancel') {
        this.$message.info('已取消')
      }
    },
    deleteOption ({ row }) {
      this.$confirm('确定删除该部门吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let params = { ids: row.id }
        let { data } = await posterRemove(params)
        if (data.code === 200) {
          this.$message.success('删除成功!')
          await this.getData()
        } else {
          this.$message.error('删除失败')
        }
      }).catch(() => {
        this.$message.info('已取消')
      })
    },
    async changeData () {
      let regActivity = /^[\u4e00-\u9fa5a-z0-9]+$/i
      if (this.form.activityName) {
        this.$message.error('请选择上级组织')
        return false
      }
      if (!this.form.activityName) {
        this.$message.error('请输入组织名称')
        return false
      } else {
        if (!regActivity.test(this.form.activityName)) {
          this.$message.info('组织名称只能由中文、字母、数字组成')
          return false
        }
      }
      if (!this.form.activityName) {
        this.$message.error('请输入负责人名称')
        return false
      } else {
        if (!regActivity.test(this.form.activityName)) {
          this.$message.info('负责人名称只能由中文、字母、数字组成')
          return false
        }
      }
      if (this.isDialogAdd) {
        let { data } = await posterAdd(this.form)
        if (data.code === 200) {
          this.$message.success('新增成功')
          await this.getData()
        } else {
          this.$message.error('新增失败')
        }
      } else {
        let { data } = await posterUpdate(this.form)
        this.$refs.upload.submit()
        if (data.code === 200) {
          this.$message.success('修改成功')
          await this.getData()
        } else {
          this.$message.error('修改失败')
        }
      }
    },
    searchOne () {
      this.queryParams.pageSize = 10
      this.queryParams.current = 1
      this.getData()
    },
    pageChange (num) {
      this.queryParams.current = num
      this.getData()
    },
    sizeChange (num) {
      this.queryParams.pageSize = num
      if (this.queryParams.current * this.queryParams.pageSize > this.total) {
        this.queryParams.current = 1
      }
      this.getData()
    },
  }
}
</script>

<style lang="scss" scoped>

.handleBox {
  width: 100%;
  margin-top: 20px;

  .inputBox {
    box-sizing: border-box;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;

    .inputItem {
      width: 25%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      span {
        margin-right: 4px;
        white-space: nowrap;
        width: 100px;
        display: inline-block;
        text-align: right;
      }

      .el-input {
        width: 200px;
      }
    }
  }

}

.mar-left {
  margin: 0 10px 0 30px;
}

.dialogBox {
  width: 60%;
  margin: 0 auto;

  .boxItem {
    margin: 20px 0;
    display: flex;

    p {
      display: inline-block;
      text-align: right;
      width: 85px;
      margin: 8px 0;
    }

  }
}

.btn_box {
  display: flex;
  justify-content: center;
}

.table .page {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload .el-upload-dragger {
  width: 100%;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  min-width: 178px;
  max-width: 300px;
  max-height: 178px;
  min-height: 50px;
  text-align: center;
}
</style>
