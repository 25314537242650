<!--suppress ALL -->
<template>
  <div class="container" v-infinite-scroll="loadComment">
    <el-page-header @back="goBack" content="帖子详情"> </el-page-header>
    <div class="Container">
      <div class="userInfo">
        <el-avatar
          :src="
            articleInfo.avatar ||
            'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
          "
          style="border: 1px solid #aaa"
        />
        <div style="margin-left: 10px">
          <p>{{ articleInfo.username }}</p>
          <p>{{ articleInfo.publishTime }}</p>
        </div>
      </div>
      <div class="mainContent" v-html="articleInfo.content">}</div>
      <div
        class="carouselList"
        v-if="articleInfo.imageList && articleInfo.imageList.length > 0"
      >
        <div class="imageList">
          <el-image
            v-for="(item, index) in articleInfo.imageList"
            :src="item"
            fit="contain"
            :class="item ? 'activeImage' : 'normalImage'"
            @click="imageClick(index)"
            :preview-src-list="articleInfo.imageList"
            :key="index"
          ></el-image>
        </div>
      </div>
      <div class="videoList" v-if="articleInfo.videoUrl">
        <video :src="articleInfo.videoUrl" controls />
      </div>
      <div class="relation">
        <div class="flex">
          <p>{{ articleInfo.adminDynamicStatisticVO.browseCount }} 浏览</p>
        </div>
        <div class="flex">
          <p>{{ articleInfo.adminDynamicStatisticVO.shareCount }} 分享</p>
        </div>
        <div class="flex">
          <p>
            <i class="el-icon-thumb"></i
            >{{ articleInfo.adminDynamicStatisticVO.thumbsUpCount }}
          </p>
        </div>
        <div class="flex">
          <p>
            <i class="el-icon-star-off"></i
            >{{ articleInfo.adminDynamicStatisticVO.forwardCount }}
          </p>
        </div>
        <div class="flex">
          <p>
            <i class="el-icon-chat-dot-square"></i
            >{{ articleInfo.adminDynamicStatisticVO.totalCommentCount }}
          </p>
        </div>
      </div>
      <div class="CheckStatusBtn" v-if="allStatus">
        <el-button type="primary" style="margin-left: 150px" @click="Approved"
          >审核通过</el-button
        >
        <el-button style="margin-left: 80px" @click="noApproved"
          >审核驳回</el-button
        >
      </div>
    </div>

    <div class="comment">
      <div class="title">
        <i class="el-icon-chat-dot-square" />
        <span>评论</span>
      </div>
      <comment-item
        v-for="(item, index) in commentList"
        :key="index"
        v-bind="item"
        :commentList="item.commentReplyVOs ? item.commentReplyVOs.records : []"
        @click="handleDeleteComment(index, $event)"
      />
    </div>
  </div>
</template>

<script>
import {
  adminCheckPassDynamic,
  adminCheckRejectDynamic,
  articleDetail,
} from "@/api/article";
import { getCommentList, removeComment } from "@/api/comment";

import commentItem from "./components/commentItem";
import CommentItem from "./components/commentItem";
import { getOriginContentStr } from "@/util/util";
import { honorWallDelete } from "@/api/honor";

export default {
  components: { CommentItem },
  data() {
    return {
      articleInfo: {
        userName: "",
        createTime: "",
        title: "",
        content: "",
        imageList: [],
        allStatus: false,
        adminDynamicStatisticVO: {
          browseCount: 0,
          thumbsUpCount: 0,
          forwardCount: 0,
          commentCount: 0,
        },
      },
      //弹出框的状态
      dialogVisible: false,
      examineVisible: false,
      commentList: [],
      commentQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
      },
    };
  },
  computed: {
    dynamicId() {
      return this.$route.query.dynamicId;
    },
  },
  async mounted() {
    const {
      data: { data },
    } = await articleDetail(this.dynamicId);
    data.content = getOriginContentStr(data.content, true);
    this.articleInfo = data;

    if (
      this.articleInfo.checkedStatus === 1 &&
      this.articleInfo.deletedStatus === 1
    ) {
      this.allStatus = true;
    } else {
      this.allStatus = false;
    }
  },
  methods: {
    carouselClick(newImage, oldImage) {
      this.imageList[newImage].active = true;
      this.imageList[oldImage].active = false;
    },
    goBack() {
      // this.$router.push({ name: "帖子首页" });
      this.$router.back();
    },
    imageClick(index) {
      this.$refs.carousel.setActiveItem(index);
    },
    //审核通过
    Approved() {
      this.$confirm("您确定通过审核操作吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 启用
          return adminCheckPassDynamic({
            dynamicId: this.articleInfo.dynamicId,
          });
        })
        .then(() => {
          this.$router.push({ path: "/article/index" });
          this.$message({
            type: "success",
            message: "审核成功!",
          });
        });
    },
    //审核驳回
    noApproved() {
      this.$confirm("您确定通过驳回操作吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 启用
          return adminCheckRejectDynamic({
            dynamicId: this.articleInfo.dynamicId,
          });
        })
        .then(() => {
          this.$router.push({ path: "/article/index" });
          this.$message({
            type: "success",
            message: "驳回成功!",
          });
        });
    },

    loadComment() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      let commentQueryParams = this.commentQueryParams;
      commentQueryParams.dynamicId = this.$route.query.dynamicId;
      if (commentQueryParams.hasNextPage) {
        if (commentQueryParams.pageSize) {
          commentQueryParams.query = {
            current: commentQueryParams.pageNum,
            size: commentQueryParams.pageSize,
          };
        }
        getCommentList(commentQueryParams).then((res) => {
          const { current, pages, records } = res.data.data;
          let arrPromisedata = records.map(this.commentItemDataFormat);
          Promise.all(arrPromisedata).then((res) => {
            this.commentList = this.commentList.concat(res);
          });
          commentQueryParams.hasNextPage = current < pages;
          commentQueryParams.pageNum++;
          this.isLoading = false;
        });
      }
    },
    async commentItemDataFormat(val) {
      const { current, pages } = val.commentReplyVOs;
      console.log(current, pages);
      if (current <= pages) {
        let commentQueryParams = {
          query: {
            current,
            size: 10000,
          },
          dynamicId: this.$route.query.dynamicId,
          commentId: val.id,
        };
        let res = await getCommentList(commentQueryParams);

        let records = res.data.data.records.map((item) => {
          item.content = getOriginContentStr(item.content, true);
          return item;
        });
        val.commentReplyVOs.records = records;
      }
      val.content = getOriginContentStr(val.content, true);
      return val;
    },
    async handleDeleteComment(index, data) {
      const { commentId, type, replyIndex } = data;
      await removeComment({ commentId: commentId });
      this.$message.success("删除成功");
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.videoList {
  width: 300px;
  height: 200px;
  overflow: hidden;
  video {
    width: 100%;
    height: 100%;
  }
}
.container {
  width: 70%;
  margin: 10px auto;

  i {
    margin-right: 10px;
    font-size: 20px;
  }

  .Container {
    width: 70%;
    margin: 20px auto;

    .userInfo {
      display: flex;
    }

    .title {
      word-wrap: normal;
      overflow: hidden; /*超出部分隐藏*/
      white-space: nowrap; /*不换行*/
      text-overflow: ellipsis; /*超出部分省略号显示*/
      margin: 25px 0;
      font-size: 20px;
    }

    .mainContent {
      line-height: 30px;
      font-size: 16px;
      margin-bottom: 20px;
    }

    .carouselList {
      width: auto;
      height: 100%;
      margin: 0 auto;

      .carouselImage {
        position: relative;
        left: 50%;
        top: 50%;
        max-height: 300px;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    .imageList {
      margin: 20px auto;
      display: table;

      .activeImage {
        width: 100px;
        height: 100px;
        margin: 0 10px;
        cursor: pointer;
        box-sizing: border-box;
        border: 2px solid #3a8ee6;
      }

      .normalImage {
        width: 100px;
        height: 100px;
        margin: 0 10px;
        cursor: pointer;
        box-sizing: border-box;
        border: 2px solid transparent;
      }

      .normalImage:hover {
        border: 2px solid #3a8ee6;
      }
    }

    .relation {
      margin: 20px auto;
      display: table;

      .flex {
        display: inline-block;
        margin: 0 30px;
        font-size: 18px;
      }
    }
    .CheckStatusBtn {
      width: 100%;
    }
  }
  .comment {
    width: 50%;
    margin: 0 auto;

    .title {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
<style lang="scss" scoped>
/deep/.el-avatar {
  width: 80px;
  height: 80px;
  img {
    width: 100%;
    height: auto !important;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 400px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
