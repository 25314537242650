var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "commentItem" }, [
    _c("div", { staticClass: "commentContent commonHover" }, [
      _c("span", { staticClass: "contentText" }, [
        _vm._v(_vm._s(_vm.username) + "："),
        _c("span", { domProps: { innerHTML: _vm._s(_vm.content) } })
      ]),
      _vm.deletedStatus === 1
        ? _c("i", {
            staticClass: "el-icon-delete",
            on: {
              click: function($event) {
                return _vm.handleDelete(_vm.commentId, "comment")
              }
            }
          })
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "commentList" },
      _vm._l(_vm.commentList, function(item, index) {
        return _c("div", { key: index, staticClass: "commentContent" }, [
          _c("div", { staticClass: "contentText" }, [
            _c("span", [_vm._v(_vm._s(item.username))]),
            item.username
              ? _c("span", [_vm._v(" 回复 " + _vm._s(item.username))])
              : _vm._e(),
            _c("span", { domProps: { innerHTML: _vm._s(item.content) } })
          ]),
          item.deletedStatus === 1
            ? _c("i", {
                staticClass: "el-icon-delete",
                on: {
                  click: function($event) {
                    return _vm.handleDelete(item.commentId, "reply", index)
                  }
                }
              })
            : _vm._e()
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }