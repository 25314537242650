<template>
  <el-dialog
    class="sizeGuide-dialog"
    title="投票选项详情"
    :visible.sync="show"
    @close="close"
    width="700px"
    :append-to-body="true"
  >
    <div class="content">
      <p>选项：{{ VoterId.content }}</p>
      <div class="vote">
        <p>投此选项的人 ({{ VoterId.voteCount }})</p>
        <ul>
          <li v-for="item in peopleList" :key="item">
            <div class="img">
              <img :src="item.avatar" alt=""/>
            </div>
            <div class="text">
              <p>{{ item.userName }}</p>
              <span>{{ item.createTime }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {lookPeopleNum} from "../../api/article";

export default {
  name: "voteDetailed",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    VoterId: {
      type: Object,
      default: () => {
      },
    },
  },
  created() {
  },
  data() {
    return {
      peopleList: []
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        lookPeopleNum(this.VoterId.id).then((res) => {
          this.peopleList = res.data.data
          console.log(this.peopleList);
        });
      }
    },
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog__header {
  background: rgb(241, 243, 243);
}

.content {
  > p {
    font-size: 20px;
  }

  .vote {
    font-size: 18px;
    border-top: solid 1px #eee;

    > p {
      padding: 15px 0 12px;
    }

    > ul {
      display: flex;
      flex-wrap: wrap;

      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 12px;
        margin-right: 44px;

        &:nth-child(3n) {
          margin-right: 0;
        }

        .img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
          }
        }

        .text {
          // width: 140px;
          p {
            overflow: hidden;
            white-space: nowrap;
            // text-overflow: ellipsis;
            font-size: 16px;
          }

          span {
            font-size: 14px;
            color: #666;
          }
        }
      }
    }
  }
}
</style>
