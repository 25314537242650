<!--suppress ALL -->
<template>
  <div class="container">
      欢迎您
<!--    <div class="Header">
      <div style="margin-left:20px">
        数据汇总统计
      </div>
      <div class="flex">
        <div style="flex: 1" v-for="(item,index) in topData" :key="index">
          <p class="totalNum">{{ item.num }}</p>
          <p class="totalContent">{{ item.content }}</p>
        </div>
      </div>
    </div>
    <div class="Footer">
      <div class="flex">
        <div class="top">
          发帖前十排行榜
        </div>
        <table-area
          :tableHeader="tableHeader"
          :tableData="tableData"
          :areaOpt="true"
          :index="false"
          :page-show="false"
          :opt-show="false">
        </table-area>
      </div>
      <div class="flex">
        <div class="top">
          发帖数统计
        </div>
        <div>
          <div>
            <el-button v-for="(item,index) in dateButton" :key="index" :class="item.active?'activity':''" @click="getDateData(item,index)">{{ item.content }}
            </el-button>
            <el-date-picker
              style="margin-left: 10px"
              v-model="dateTime" :clearable="false"
              type="datetimerange"
              value-format="timestamp"
              :pickerOptions="pickerOptions"
              @change="dataSelectChange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div id="terminal"></div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>

import tableArea from '../../components/table'

export default {
  name: 'homePage',
  inject: ['homePage'],
  components: {tableArea},
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 90%;
  margin: 10px auto;

  .Header {
    margin-top: 30px;
    height: 30%;
    font-size: 18px;
    color: #0079FE;

    .flex {
      margin-top: 40px;
      display: flex;

      .totalNum {
        text-align: center;
        color: black;
        font-weight: 400;
        font-size: 30px;
      }

      .totalContent {
        text-align: center;
        color: black;
        font-size: 14px;
      }
    }
  }

  .Footer {
    border-top: 1px solid #ccc;
    margin-top: 30px;
    height: 70%;
    display: flex;

    .flex {
      flex: 1;
      margin: 20px;

      .top {
        margin: 30px 0 20px 0;
        font-size: 18px;
        color: #0079FE;
      }

      #terminal {
        width: 100%;
        height: 430px;
        overflow-y: scroll;
      }
    }
  }
}
</style>
<style scoped>
.activity {
  color: #3a8ee6;
  outline: 0;
  background-color: #ecf5ff;
}


.el-date-range-picker {
  width: 550px;
}

.el-date-table td.in-range div {
  background-color: #efefef;

}
</style>
