<template>
  <basic-container>
    <avue-crud
      ref="crud"
      v-model="formData"
      :data="data"
      :option="option"
      :page.sync="page"
      :table-loading="loading"
      @search-change="searchChange"
      @search-reset="searchReset"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menu" slot-scope="scope">
        <el-button size="small" type="text" @click.stop="updateFn(scope)"
          >修改</el-button
        >
        <el-button
          :style="scope.row.status === 1 ? 'color: red;' : ''"
          size="small"
          type="text"
          @click.stop="rowStatus(scope)"
        >
          {{ scope.row.status === 1 ? "停用" : "启用" }}
        </el-button>
        <el-button
          size="small"
          style="color: red"
          type="text"
          @click.stop="deleteFn(scope)"
          >删除</el-button
        >
      </template>
      <template slot="menuLeft" slot-scope="scope">
        <el-button size="small" type="primary" @click.stop="addFn(scope)"
          >新增</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :title="isDialogAdd ? '新增' : '修改'"
      :visible.sync="showDialog"
      width="60%"
    >
      <div class="dialogBox">
        <div class="boxItem">
          <p>活动名称：</p>
          <el-input
            v-model="form.activityName"
            maxlength="24"
            placeholder="请输入活动名称"
            show-word-limit
          ></el-input>
        </div>
        <div class="boxItem">
          <p>活动图片：</p>
          <el-upload
            ref="upload"
            :auto-upload="false"
            :on-change="handleChange"
            :show-file-list="false"
            action="#"
            class="avatar-uploader"
            drag
            style="display: inline-flex"
          >
            <img
              v-if="form.imgUrl"
              ref="showImage"
              :src="form.imgUrl"
              alt=""
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <cropper-image
            :option="cropOption"
            :visible="dialogCrop"
            @finishCrop="finishCrop"
          ></cropper-image>
        </div>
        <div class="boxItem">
          <p>选择类型：</p>
          <el-radio-group
            v-model="form.type"
            style="margin-top: 10px"
            @change="handleChooseType"
          >
            <el-radio :label="0">帖子</el-radio>
            <el-radio :label="1">投票</el-radio>
            <el-radio :label="2">文章</el-radio>
            <el-radio :label="3">外链</el-radio>
          </el-radio-group>
        </div>
        <div class="boxItem" v-if="form.type === 0">
          <p>选择帖子：</p>
          <el-select
            v-model="form.articleId"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in articleOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="boxItem" v-if="form.type === 1">
          <p>选择投票：</p>
          <el-select
            v-model="form.voteId"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in voteOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="boxItem" v-if="form.type === 2">
          <p>文章内容：</p>
          <editor ref="editorOne" ></editor>
        </div>
        <div v-if="form.type===3" class="boxItem">
          <p>外链：</p>
          <el-input type="textarea" :rows="4" placeholder="请输入外链" v-model="form.urlContent"/>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitFn">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  posterAdd,
  posterList,
  posterRemove,
  posterUpdate,
} from "@/api/poster";
import { articleList } from "@/api/article";
import { isURL } from '@/util/util'
import CropperImage from "./upload";
import Editor from "./wangEditor.vue";

let form = {
  activityName: "",
  images: "",
  posterAddress: "",
  voteId:'',
  type: '',
  articleId: '',
  content: '',
};

export default {
  components: { CropperImage, Editor },
  data() {
    return {
      articleOptions: [],
      voteOptions: [],
      options: {},
      isDialogAdd: false,
      showDialog: false,
      cropperVisible: false,
      form: JSON.parse(JSON.stringify(form)) ,
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      voteQueryParams: {
        pageNum: 1,
        pageSize: 10000,
        isVote: 1,
      },
      articeQueryParams: {
        pageNum: 1,
        pageSize: 10000,
        isVote: 0,
        quoteType: 1
      },
      option: {
        searchShow: true,
        searchMenuSpan: 4,
        searchSpan: 8,
        index: true,
        indexLabel: "序号",
        menuWidth: 200,
        searchLabelWidth: 120,
        menuAlign: "center",
        align: "center",
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "活动名称",
            prop: "activityName",
            search: true,
            maxlength: 24,
            showWordLimit: true,
            rules: [
              {
                required: true,
                showWordLimit: true,
                message: "请输入活动名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "图片",
            prop: "imgUrl",
            type: "upload",
            span: 10,
            width: 150,
          },
          {
            label: "创建时间",
            prop: "createTime",
            search: false,
            type: "date",
            width: 180,
            valueFormat: "yyyy-mm-dd",
          },
          {
            label: "创建人",
            prop: "userName",
            search: true,
            width: 200,
            maxlength: 24,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入标题",
                trigger: "blur",
              },
            ],
          },
          {
            label: "状态",
            prop: "status",
            width: 80,
            search: true,
            dicData: [
              { value: 1, label: "启用" },
              { value: 0, label: "停用" },
            ],
            type: "select",
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "select",
              },
            ],
          },
        ],
      },
      data: [],
      dialogCrop: false, // 裁剪图片弹框 裁剪组件的基础配置option
      cropOption: {
        img: "", // 裁剪图片的地址
        size: 1, // 裁剪生成图片的质量
        outputType: "jpeg", // 裁剪生成图片的格式
        full: true, // 是否输出原图比例的截图
        canMove: true,
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        autoCrop: true, // 是否默认生成截图框
        canScale: false, // 图片是否允许滚轮缩放
        autoCropWidth: 480, // 默认生成截图框宽度
        autoCropHeight: 270, // 默认生成截图框高度
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [16, 9], // 截图框的宽高比例
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        fixedBox: true, // 固定截图框大小 不允许改变
      }
    };
  },
  watch: {
    showDialog(newVal) {
      if(!newVal) {
        this.articeId = ''
      }
    }
  },
  created() {
    // 帖子
    articleList(this.articeQueryParams).then((res) => {
      this.articleOptions = res.data.data.records;
    });
    // 投票
    articleList(this.voteQueryParams).then((res) => {
      this.voteOptions = res.data.data.records;
    });
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      posterList({
        pageNum: page.currentPage,
        pageSize: page.pageSize,
        ...Object.assign(params, this.query),
      }).then((res) => {
        this.loading = false;
        this.page.total = res.data.data.total;
        this.data = res.data.data.records.map((item) => {
          const {articleId, type} = item
          return { ...item, articleId: type === 0 ? articleId : '', voteId: type === 1 ? articleId : '' };
        });
      });
    },
    searchChange(params, done) {
      this.showDialog = false;
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    addFn() {
      this.showDialog = true;
      this.isDialogAdd = true;
      this.form =  JSON.parse(JSON.stringify(form));
    },
    updateFn(row) {
      this.showDialog = true;
      this.isDialogAdd = false;
      this.form = row.row
      if(this.form.type === 2) {
        delete this.form.articleId
        setTimeout(()=>{
          this.$refs.editorOne.setHtml(row.row.content)
        },100)
      }
      if(this.form.type === 3) {
        this.form.urlContent = this.form.content
        this.form.content = ''
      }
    },
    handleChooseType(value) {
      if(value === 2) {
        setTimeout(()=>{
          this.$refs.editorOne.setHtml(this.form.content)
        },100)
      }
    },
    async rowStatus({ row }) {
      let res;
      if (row.status === 1) {
        res = await this.$confirm("确定停用该条活动吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      }
      if (!res || res === "confirm") {
        this.loading = true;
        let params = { id: row.id, status: row.status ? "0" : "1" };
        let { data } = await posterUpdate(params).finally(() => {
          this.loading = false;
        });
        this.loading = false;
        if (data.code === 200) {
          this.$message.success("修改成功!");
          await this.onLoad(this.page);
        } else {
          this.$message.error("修改失败");
        }
      } else if (res === "cancel") {
        this.$message.info("已取消修改");
      }
    },
    deleteFn(row) {
      this.$confirm("此操作将删除该海报, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          let { data } = await posterRemove(row.row.id);
          this.loading = false;
          if (data.code === 200) {
            this.$message.success("删除成功!");
            await this.onLoad(this.page);
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message.info("已取消删除");
        });
    },
    submitFn() {
      let form = this.form
      if (!form.activityName) {
        this.$message.warning("请输入活动名称");
        return false;
      }
      if (!form.imgUrl) {
        this.$message.warning("请选择图片");
        return false;
      }
      if (form.type === '') {
        this.$message.warning("请选择类型");
        return false;
      }
      if(form.type === 0){
        if(!form.articleId){
          this.$message.warning("请选择帖子");
          return false;
        }
        form.content = ''
      } else if(form.type === 1) {
        if(!form.voteId){
          this.$message.warning("请选择投票");
          return false;
        }
        form.content = ''
        form.articleId = form.voteId
      } else if(form.type===2){
        form.articleId = '';
        form.content = this.$refs.editorOne.getHtml();
        if(!form.content){
          this.$message.warning("请输入文章内容");
          return false;
        } else if(form.content.length > 10000) {
          this.$message.warning("内容过长，请修改");
          return false;
        }
      } else if(form.type === 3){
        if(!form.urlContent) {
          this.$message.warning("请输入外链");
          return false;
        }
        if(!isURL(form.urlContent)) {
          this.$message.warning("请输入正确的外链地址");
          return false;
        }
        this.form.content = form.urlContent
      }

      if (this.isDialogAdd) {
        posterAdd(form).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("新增成功");
            this.onLoad(this.page);
            this.showDialog = false;
          } else {
            this.$message.error("新增失败");
          }
        });
      } else {
        posterUpdate(form).then((res) => {
          if (res.data.code === 200) {
            this.$message.success("修改成功");
            this.onLoad(this.page);
            this.showDialog = false;
          } else {
            this.$message.error("修改失败");
          }
        });
      }

    },
    handleChange(file) {
      const isJPG =
        file.raw.type === "image/jpeg" ||
        file.raw.type === "image/png" ||
        file.raw.type === "image/jpg";
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isJPG)
        return this.$message.error("上传海报图片只能是 JPG 或 PNG 格式");
      if (!isLt1M) return this.$message.error("上传海报图片不能大于1M");
      this.$nextTick(() => {
        let reader = new FileReader();
        reader.readAsDataURL(file.raw);
        reader.onload = () => {
          this.cropOption.img = URL.createObjectURL(file.raw);
          this.form.images = file.raw;
          this.dialogCrop = true;
        };
      });
    },
    // 确认裁剪
    finishCrop(e) {
      if (e.code === 200) {
        this.$message.success("剪切图片成功");
        this.dialogCrop = false;
        this.imageUrl = e.data.link;
        this.form.imgUrl = e.data.link;
      } else {
        this.$message.error("剪切图片失败");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialogBox {
  margin: 0 auto;
  padding: 0 20px 0 10px;

  .boxItem {
    margin: 20px 0;
    display: flex;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }

    p {
      //display: inline-block;
      //text-align: left;
      width: 85px;
      margin: 8px 0;
      white-space: nowrap;
    }
  }
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload .el-upload-dragger {
  width: 100%;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  min-width: 178px;
  max-width: 300px;
  max-height: 178px;
  min-height: 50px;
  text-align: center;
}
</style>
