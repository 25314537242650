var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          option: _vm.option,
          "table-loading": _vm.loading,
          data: _vm.data,
          page: _vm.page
        },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "search-change": _vm.searchChange,
          "search-reset": _vm.searchReset,
          "current-change": _vm.currentChange,
          "size-change": _vm.sizeChange,
          "refresh-change": _vm.refreshChange,
          "on-load": _vm.onLoad
        },
        scopedSlots: _vm._u([
          {
            key: "menu",
            fn: function(scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.rowUpdate(scope)
                      }
                    }
                  },
                  [_vm._v("修改\n      ")]
                ),
                _c(
                  "el-button",
                  {
                    style: scope.row.status === 1 ? "color: red;" : "",
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.rowStatus(scope)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.status === 1 ? "停用" : "启用") +
                        "\n      "
                    )
                  ]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { color: "red" },
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.rowDel(scope)
                      }
                    }
                  },
                  [_vm._v("删除\n      ")]
                )
              ]
            }
          },
          {
            key: "menuLeft",
            fn: function(scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.rowSave(scope)
                      }
                    }
                  },
                  [_vm._v("新增\n      ")]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.formData,
          callback: function($$v) {
            _vm.formData = $$v
          },
          expression: "formData"
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isDialogAdd ? "新增" : "修改",
            visible: _vm.showDialog,
            "modal-append-to-body": false,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("span", [_vm._v("敏感词内容：")]),
              _c("el-input", {
                staticStyle: { width: "80%" },
                attrs: {
                  type: "text",
                  maxlength: "24",
                  "show-word-limit": "",
                  placeholder: "请输入敏感词内容"
                },
                on: { input: _vm.inputChange },
                model: {
                  value: _vm.form.word,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "word", $$v)
                  },
                  expression: "form.word"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.changeData } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }