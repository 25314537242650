<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      v-model="form"
      :page.sync="page"
      @row-save="rowSave"
      @row-update="rowUpdate"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="loadData"
    >
      <template slot="avatar" slot-scope="scope">
        <div class="imgs-wrap" v-if="scope.row.avatar">
          <el-image
            :key="scope.row.avatar"
            :src="scope.row.avatar"
            :preview-src-list="[scope.row.avatar]"
            >
          </el-image>
        </div>
      </template>

      <template slot="menu" slot-scope="scope">
        <el-button
          size="small"
          type="text"
          @click.stop="handleDetail(scope.row)"
        >查看/绑定用户
        </el-button>
<!--
        <el-button
          size="small"
          style="color: red"
          type="text"
          @click.stop="rowDel(scope.row)"
        >删除
        </el-button>
-->

        <el-button
          size="mini"
          type="text"
          :style="scope.row.status === 1 ? 'color: red': ''"
          @click="rowDel(scope.row)">
          {{ scope.row.status === 1 ? '禁用' : '启用'}}
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
      :append-to-body="true"
      :modal-append-to-body="false"
      :visible.sync="showDialog"
      width="40%"
      title="新增绑定人员"
      :before-close="handleCancel"
    >
      <el-form>
        <el-form-item label="选择人员"  v-if="!showDetail">
          <el-cascader
            ref="cascaderAddr"
            v-model="deptChoose"
            :props="props"
            :options="deptOptions"
            @change="handleChange"
            style="width: 50%"
            clearable
            filterable
            :before-filter="handleSearch"
            :show-all-levels="false"
            @input.native="handleInput"
            @visible-change="handleMenuChange"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <el-form ref="form" :model="addForm" label-width="150px">
        <el-form-item label="根据姓名搜索">
          <el-autocomplete
            style="width: 90%"
            v-model="addForm.nameDisplay"
            :fetch-suggestions="querySearchNameDisplayAsync"
            placeholder="根据姓名搜索"
            @select="handleNameDisplaySelect"
          >
            <template slot-scope="{ item }">
              <div class="search_name">{{ item.nameDisplay }}</div>
              <span class="search_addr">{{ item.deptStr }}</span>
            </template>
          </el-autocomplete>
        </el-form-item>
        <el-form-item label="根据工号搜索">
          <el-autocomplete
            style="width: 90%"
            v-model="addForm.empNo"
            :fetch-suggestions="querySearchEnmNoAsync"
            placeholder="请输入工号"
            @select="handleEmpNoSelect"
          >
            <template slot-scope="{ item }">
              <div class="search_name">{{ item.nameDisplay }}</div>
              <span class="search_addr">{{ item.deptStr }}</span>
            </template>
          </el-autocomplete>
        </el-form-item>
      </el-form>
      <div class="discript">一次只能绑定一个用户，请不要同时使用工号和姓名添加</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="changeData">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :append-to-body="true"
      :modal-append-to-body="false"
      :visible.sync="showDetail"
      width="60%"
      title="官方身份绑定列表"
    >
      <el-button size="small" type="primary" @click.stop="addNewUser"
      >新增
      </el-button>

      <el-table
        :data="orgDetail"
        :show-header="true"
        style="width: 100%">
        <el-table-column
          prop="empNo" label="工号">
        </el-table-column>
        <el-table-column
          prop="username" label="用户名" >
        </el-table-column>
        <el-table-column
          prop="deptStr" label="部门" width="200">
        </el-table-column>
        <el-table-column
          prop="updateUser" label="修改人">
        </el-table-column>
        <el-table-column
          prop="updateTime" label="修改时间">
        </el-table-column>
        <el-table-column width="100" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              style=" color: red"
              @click="handleRemove(scope.row)">
              删除
              </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  adminList,
  listBindedUser,
  officialIdentityBind,
  officialIdentityDisable, officialIdentityEnable,
  officialIdentitySave,
  officialIdentityUnBind,
  officialIdentityUpdate
} from '@/api/officialIdentity'
import {listGeelyUser} from "@/api/system/geelyuser";

let addForm = {
  empNo: '',
  nameDisplay: '',
  userId: '',
};
export default {
  name: "email",
  data() {
    return {
      addForm: JSON.parse(JSON.stringify(addForm)),
      option: {
        index: true,
        indexLabel: "序号",
        menuWidth: 300,
        menuAlign: "center",
        align: "center",
        viewBtn: false,
        addBtn: true,
        editBtn: true,
        delBtn: false,
        column: [
          {
            label: "用户名",
            prop: "username",
            maxlength: 10,
            span: 24
          },
          {
            label: "头像",
            prop: "avatar",
            slot: 'avatar',
            type: 'upload',
            listType: 'picture-img',
            width: '120px',
            span: 24,
            propsHttp: {
              url: 'link'
            },
            tip: '只能上传jpg/png用户头像，且不超过500kb',
            action: '/api/oa-resource/oss/endpoint/put-file'
          },
          {
            label: "修改人",
            prop: "updateUser",
            span: 24,
            addDisplay: false,
            editDisplay: false,
          },
          {
            label: "修改时间",
            prop: "updateTime",
            span: 24,
            addDisplay: false,
            editDisplay: false,
          },
          {
            label: "状态",
            type: "select",
            prop: "status",
            dataType: 'number',
            addDisplay: false,
            editDisplay: false,
            dicUrl: "/api/oa-system/dict-biz/dictionary?code=common_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
          },
        ],
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      form: {},
      query: {},
      loading: true,
      data: [],
      showDialog: false,
      userChoose: '',
      deptOptions: [],
      props: {
        lazy: true,
      },
      rowUpdateId: "",
      id: "",
      user: "",
      userId: "",
      deptChoose: [],
      showDetail: false,
      orgData: [],
      orgProps: {
        children: 'children',
        label: 'title'
      },
      orgValue: [],
      orgChoose: '',
      orgDetail: [],
      singleData: {}
    }
  },
  mounted() {

  },
  methods: {
    // 更新官方身份
    rowUpdate(row, index, done, loading) {
      row.officialUserId = row.id
      delete  row.updateTime;
      delete row.updateUser;
      officialIdentityUpdate(row).then(() => {
        this.$message({
          type: "success",
          message: "操作成功!"
        });
        // 数据回调进行刷新
        done(row);
        this.onLoad(this.page)
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowSave(row, done, loading) {
      officialIdentitySave(row).then((res) => {
        // 获取新增数据的相关字段
        this.tempEditRow = null;
        const data = res.data.data;
        row.id = data.id;
        this.$message({
          type: "success",
          message: "操作成功!"
        });
        // 数据回调进行刷新
        done(row);
        this.onLoad(this.page)
      }, error => {
        window.console.log(error);
        loading();
      });
    },

    async loadData(page, params = {}) {
      this.data = await this.onLoad(page, params);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      adminList({
        pageNum: page.currentPage,
        pageSize: page.pageSize,
        ...Object.assign(params, this.query),
      }).then(res => {
        this.loading = false;
        this.page.total = res.data.data.total;
        this.data = res.data.data.records
      });
    },
    // 异步搜索用户
    querySearchEnmNoAsync(queryString, cb) {
      if(queryString && queryString.trim()){
        listGeelyUser({empNo: queryString.trim()}).then(res=>{
          cb(res.data.data.records)
        })
      }
    },
    querySearchNameDisplayAsync(queryString, cb) {
      if(queryString && queryString.trim()){
        listGeelyUser({nameDisplay: queryString.trim()}).then(res=>{
          cb(res.data.data.records)
        })
      }
    },
    // 当员工选择工号
    handleEmpNoSelect(item){
      if(this.checkUserSelect(item)){
        this.addForm.empNo = item.nameDisplay
      }
    },
    // 选择姓名时
    handleNameDisplaySelect(item){
      if( this.checkUserSelect(item)){
        this.addForm.nameDisplay = item.nameDisplay
      }
    },
    checkUserSelect(item){
      this.addForm.userId = item.id
      return true
    },
    initData() {
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    handleChange(value) {
      this.userChoose = value[value.length - 1]
    },
    async changeData() {
      if ((this.showDetail || this.userChoose) && this.orgValue) {
        this.loading = true;
        let res
        if (this.showDetail) {
          res = await officialIdentityBind(
            {
              "normalUserIds": [this.addForm.userId],
              "officialUserId": this.tempOfficeData.id
            }
          )
        }
        this.showDialog = false
        this.showDetail = false
        this.loading = false
        this.userChoose = '';
        this.deptChoose = []
        this.orgValue = []
        this.orgChoose = ''
        const {code} = res.data
        if (code === 200) {
          this.addForm = JSON.parse(JSON.stringify(addForm))
          this.$message.success("新增成功!");
          await this.onLoad(this.page);
        } else {
          this.$message.error("新增失败");
        }
      } else {
        this.$message.warning("请正确选择！");
      }
      this.deptOptions = []
    },
    async handleDetail(data) {
      const {id} = data
      const res = await listBindedUser({officialUserId: id})
      this.showDetail = true
      this.orgDetail = res.data.data
      this.tempOfficeData = data
    },
    //点击了删除
    rowDel(row) {
      this.$confirm("此操作该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          if(row.status === 1){
           await officialIdentityDisable({officialUserId: row.id});
          }else{
            await officialIdentityEnable({officialUserId: row.id});
          }
          this.loading = false;
          this.$message.success("操作成功！")
          this.onLoad(this.page);
        })
        .catch(() => {
          this.loading = false;
          this.$message.info("已取消操作");
        });
    },
    //新增绑定
    addNewUser() {
      this.showDialog = true;
    },
    async handleSearch() {
      this.$refs.cascaderAddr.$refs.panel.activePath = []
      let  res = {}
      this.deptOptions = res.data.data.list.map(item => {
        const {dbId, nameDisplay} = item.source
        return {value: dbId, label: nameDisplay, leaf: true}
      })
    },
    handleInput(e) {
      const {value} = e.target
      if (!value) {
        this.deptOptions = []
      }
    },
    handleCancel() {
      this.showDialog = false
      this.loading = false
      this.deptOptions = []
      this.orgChoose = ''
    },
    handleMenuChange(boolean) {
      if (boolean) {
        this.deptChoose = []
        this.userChoose = ''
        this.$refs.cascaderAddr.$refs.panel.activePath = [];
        this.deptOptions = []
      }
    },
    currentChecked(data, SelectedObj) {
      if (this.showDetail) {
        this.singleData.company = data.title
        this.singleData.companyId = data.id
        this.orgChoose = data.title
        let currentData = data; //暂存选中节点
        this.$refs.treeForm.setCheckedKeys([]); //删除所有选中节点
        this.$refs.treeForm.setCheckedNodes([currentData]); //选中已选中节点
      } else {
        this.orgValue = SelectedObj.checkedNodes.map(item => {
          return {company: item.title, companyId: item.id}
        })
        this.orgChoose = SelectedObj.checkedNodes.map(item => {
          return item.title
        })
      }
    },
    async handleRemove(data) {
      this.$confirm("此操作将解绑该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          await officialIdentityUnBind({
            "normalUserIds": [data.normalUserId],
            "officialUserId": this.tempOfficeData.id
          })
          this.$message.success("解绑成功!");
          this.loading = false;
          this.showDetail = false
          await this.onLoad(this.page);
        })
        .catch(() => {
          this.loading = false;
          this.$message.info("已取消绑定");
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.imgs-wrap {
  max-width: 100%;
  display: flex;
  overflow-x: auto;
  .el-image {
    background: #e1e1e1;
    width: 50px;
    max-height: 50px;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 5px;
    ::v-deep .el-image__preview {
      width: 100%;
      height: unset !important;
    }
  }
}
.discript{
  color: gray;
  margin-bottom: 10px;
  float: right;
  text-align: right;
}
</style>
