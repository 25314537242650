var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "append-to-body": true,
        "modal-append-to-body": false,
        visible: _vm.visible,
        title: "",
        width: "40%"
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c("div", { staticClass: "cropper-content" }, [
        _c("div", { staticClass: "cropper-box" }, [
          _c(
            "div",
            { staticClass: "cropper" },
            [
              _c("vue-cropper", {
                ref: "cropper",
                attrs: {
                  autoCrop: _vm.option.autoCrop,
                  autoCropHeight: _vm.option.autoCropHeight,
                  autoCropWidth: _vm.option.autoCropWidth,
                  canMove: _vm.option.canMove,
                  canMoveBox: _vm.option.canMoveBox,
                  canScale: _vm.option.canScale,
                  centerBox: _vm.option.centerBox,
                  enlarge: _vm.option.enlarge,
                  fixed: _vm.option.fixed,
                  fixedBox: _vm.option.fixedBox,
                  fixedNumber: _vm.option.fixedNumber,
                  full: _vm.option.full,
                  height: _vm.option.height,
                  img: _vm.option.img,
                  info: _vm.option.info,
                  infoTrue: _vm.option.infoTrue,
                  maxImgSize: _vm.option.maxImgSize,
                  mode: _vm.option.mode,
                  original: _vm.option.original,
                  outputSize: _vm.option.outputSize,
                  outputType: _vm.option.outputType
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "footer-btn" }, [
            _c(
              "div",
              { staticClass: "scope-btn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-zoom-in",
                      plain: "",
                      size: "mini",
                      type: "danger"
                    },
                    on: {
                      click: function($event) {
                        return _vm.changeScale(1)
                      }
                    }
                  },
                  [_vm._v("放大")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      icon: "el-icon-zoom-out",
                      plain: "",
                      size: "mini",
                      type: "danger"
                    },
                    on: {
                      click: function($event) {
                        return _vm.changeScale(-1)
                      }
                    }
                  },
                  [_vm._v("缩小")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { plain: "", size: "mini", type: "danger" },
                    on: { click: _vm.rotateLeft }
                  },
                  [_vm._v("↺ 左旋转")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { plain: "", size: "mini", type: "danger" },
                    on: { click: _vm.rotateRight }
                  },
                  [_vm._v("↻ 右旋转")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "upload-btn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "success" },
                    on: {
                      click: function($event) {
                        return _vm.uploadImg("blob")
                      }
                    }
                  },
                  [_vm._v("确定")]
                )
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }