var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            page: _vm.page
          },
          on: {
            "update:page": function($event) {
              _vm.page = $event
            },
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad
          },
          model: {
            value: _vm.form,
            callback: function($$v) {
              _vm.form = $$v
            },
            expression: "form"
          }
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadExcel }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }