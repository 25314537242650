var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "sizeGuide-dialog",
      attrs: {
        title: "投票选项详情",
        visible: _vm.show,
        width: "700px",
        "append-to-body": true
      },
      on: {
        "update:visible": function($event) {
          _vm.show = $event
        },
        close: _vm.close
      }
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("p", [_vm._v("选项：" + _vm._s(_vm.VoterId.content))]),
        _c("div", { staticClass: "vote" }, [
          _c("p", [
            _vm._v("投此选项的人 (" + _vm._s(_vm.VoterId.voteCount) + ")")
          ]),
          _c(
            "ul",
            _vm._l(_vm.peopleList, function(item) {
              return _c("li", { key: item }, [
                _c("div", { staticClass: "img" }, [
                  _c("img", { attrs: { src: item.avatar, alt: "" } })
                ]),
                _c("div", { staticClass: "text" }, [
                  _c("p", [_vm._v(_vm._s(item.userName))]),
                  _c("span", [_vm._v(_vm._s(item.createTime))])
                ])
              ])
            }),
            0
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }