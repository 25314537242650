var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            page: _vm.page
          },
          on: {
            "update:page": function($event) {
              _vm.page = $event
            },
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad
          },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function(scope) {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.handleEdit(scope.row)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { color: "red" },
                      attrs: { type: "text", size: "small" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.rowDel(scope.row)
                        }
                      }
                    },
                    [_vm._v("删除")]
                  )
                ]
              }
            },
            {
              key: "types",
              fn: function(ref) {
                var row = ref.row
                return _vm._l(_vm.computeTypes(row.$types), function(
                  item,
                  index
                ) {
                  return _c(
                    "el-tag",
                    { key: index, staticStyle: { "margin-right": "10px" } },
                    [_vm._v(_vm._s(item) + "\n      ")]
                  )
                })
              }
            }
          ]),
          model: {
            value: _vm.form,
            callback: function($$v) {
              _vm.form = $$v
            },
            expression: "form"
          }
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.handleAdd($event)
                    }
                  }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addForm.edit ? "修改发言人" : "新增发言人",
            "append-to-body": "",
            visible: _vm.addPublishDialog,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.addPublishDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.addForm,
                rules: _vm.rules,
                "label-width": "80px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名搜索", prop: "username" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择",
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": _vm.remoteSearchByUserName,
                        loading: _vm.loading,
                        width: "200px",
                        disabled: _vm.addForm.edit
                      },
                      model: {
                        value: _vm.addForm.username,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "username", $$v)
                        },
                        expression: "addForm.username"
                      }
                    },
                    _vm._l(_vm.usernameOptions, function(item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.nameDisplay, value: item.id }
                        },
                        [
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.nameDisplay))
                          ]),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                float: "right",
                                color: "#8492a6",
                                "font-size": "13px"
                              }
                            },
                            [_vm._v(_vm._s(item.deptStr))]
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "赋予权限", prop: "types" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.addForm.types,
                        callback: function($$v) {
                          _vm.$set(_vm.addForm, "types", $$v)
                        },
                        expression: "addForm.types"
                      }
                    },
                    _vm._l(_vm.dictData, function(item) {
                      return _c(
                        "el-checkbox",
                        { key: item.dictKey, attrs: { label: item.dictKey } },
                        [_vm._v(_vm._s(item.dictValue) + "\n          ")]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.addPublishDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }