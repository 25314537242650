var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "basic-container",
        [
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.option,
                search: _vm.search,
                "table-loading": _vm.loading,
                data: _vm.data,
                permission: _vm.permissionList,
                "before-open": _vm.beforeOpen,
                page: _vm.page
              },
              on: {
                "update:search": function($event) {
                  _vm.search = $event
                },
                "row-del": _vm.rowDel,
                "row-save": _vm.rowSave,
                "update:page": function($event) {
                  _vm.page = $event
                },
                "search-change": _vm.searchChange,
                "search-reset": _vm.searchReset,
                "selection-change": _vm.selectionChange,
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "refresh-change": _vm.refreshChange,
                "on-load": _vm.onLoad
              },
              scopedSlots: _vm._u([
                {
                  key: "deptName",
                  fn: function(ref) {
                    var row = ref.row
                    return [_c("el-tag", [_vm._v(_vm._s(row.deptName))])]
                  }
                },
                {
                  key: "menu",
                  fn: function(scope) {
                    return [
                      _vm.userInfo.role_name.includes("admin")
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.handleDisable(scope.row)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.status === 1 ? "禁用" : "启用"
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.form,
                callback: function($$v) {
                  _vm.form = $$v
                },
                expression: "form"
              }
            },
            [
              _c(
                "template",
                { slot: "menuLeft" },
                [
                  _vm.permission.user_add
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            plain: "",
                            icon: "el-icon-plus"
                          },
                          on: { click: _vm.addUserFromCenter }
                        },
                        [_vm._v("新增\n        ")]
                      )
                    : _vm._e(),
                  _vm.permission.user_role
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "info",
                            size: "small",
                            plain: "",
                            icon: "el-icon-user"
                          },
                          on: { click: _vm.handleGrant }
                        },
                        [_vm._v("角色配置\n        ")]
                      )
                    : _vm._e(),
                  _vm.userInfo.role_name.includes("admin")
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "warning",
                            size: "small",
                            plain: "",
                            icon: "el-icon-download"
                          },
                          on: { click: _vm.handleExport }
                        },
                        [_vm._v("全部导出\n        ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "管理员新增",
                "append-to-body": "",
                visible: _vm.selectUserDialog,
                width: "40%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.selectUserDialog = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.addForm, "label-width": "150px" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "根据姓名搜索" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "90%" },
                        attrs: {
                          "fetch-suggestions": _vm.querySearchNameDisplayAsync,
                          placeholder: "根据姓名搜索"
                        },
                        on: { select: _vm.handleNameDisplaySelect },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("div", { staticClass: "search_name" }, [
                                  _vm._v(_vm._s(item.nameDisplay))
                                ]),
                                _c("span", { staticClass: "search_addr" }, [
                                  _vm._v(_vm._s(item.deptStr))
                                ])
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.addForm.nameDisplay,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "nameDisplay", $$v)
                          },
                          expression: "addForm.nameDisplay"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "根据工号搜索" } },
                    [
                      _c("el-autocomplete", {
                        staticStyle: { width: "90%" },
                        attrs: {
                          "fetch-suggestions": _vm.querySearchEnmNoAsync,
                          placeholder: "请输入工号"
                        },
                        on: { select: _vm.handleEmpNoSelect },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("div", { staticClass: "search_name" }, [
                                  _vm._v(_vm._s(item.nameDisplay))
                                ]),
                                _c("span", { staticClass: "search_addr" }, [
                                  _vm._v(_vm._s(item.deptStr))
                                ])
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.addForm.empNo,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "empNo", $$v)
                          },
                          expression: "addForm.empNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "discript" }, [
                _vm._v("一次只能绑定一个用户，请不要同时使用工号和姓名添加")
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.selectUserDialog = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitAddUser }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "用户角色配置",
                "append-to-body": "",
                visible: _vm.roleBox,
                width: "345px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.roleBox = $event
                }
              }
            },
            [
              _c("el-tree", {
                ref: "treeRole",
                attrs: {
                  data: _vm.roleGrantList,
                  "show-checkbox": "",
                  "check-strictly": "",
                  "default-expand-all": "",
                  "node-key": "id",
                  "default-checked-keys": _vm.roleTreeObj,
                  props: _vm.props
                }
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.roleBox = false
                        }
                      }
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitRole }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "用户数据导入",
                "append-to-body": "",
                visible: _vm.excelBox,
                width: "555px"
              },
              on: {
                "update:visible": function($event) {
                  _vm.excelBox = $event
                }
              }
            },
            [
              _c(
                "avue-form",
                {
                  attrs: {
                    option: _vm.excelOption,
                    "upload-after": _vm.uploadAfter
                  },
                  model: {
                    value: _vm.excelForm,
                    callback: function($$v) {
                      _vm.excelForm = $$v
                    },
                    expression: "excelForm"
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "excelTemplate" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleTemplate }
                        },
                        [
                          _vm._v("\n            点击下载"),
                          _c("i", {
                            staticClass: "el-icon-download el-icon--right"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }