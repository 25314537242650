<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      v-model="form"
      :page.sync="page"
      :search="query"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @selection-change="selectionChange"
      @on-load="loadData"
    >

      <template slot="imageList" slot-scope="scope">
        <div class="imgs-wrap" v-if="scope.row.imageList">
          <el-image
            v-for="img in scope.row.imgUrlList"
            :key="img"
            :src="img"
            :preview-src-list="[img]">
          </el-image>
        </div>
        <div class="video-wrap" v-if="scope.row.videoUrl">
          <video class="video" width="60px" height="40px" :src="scope.row.videoUrl"
                 @click="handleVideoClick(scope.row.videoUrl)"></video>
        </div>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button v-if="permission.article_detail" type="text" size="small" @click.stop="rowView(scope)"
        >查看详情
        </el-button>
        <el-button type="text" size="small" @click.stop="addDynamicTag(scope.row)">
          设置标签
        </el-button>
        <el-button v-if="permission.article_top" type="text" size="small" @click.stop="rowTop(scope)"
        >{{ scope.row.topSort === 0 ? "设为置顶" : "取消置顶" }}
        </el-button>
        <el-button type="text" size="small" @click.stop="rowBottom(scope.row)"
        >{{ scope.row.bottomSort === 0 ? "设为置底" : "取消置底" }}
        </el-button>
        <el-button
          type="text"
          size="small"
          v-if="permission.article_delete && scope.row.deletedStatus===3"
          @click.stop="rowRollbackDel(scope)"
        >
          撤销
        </el-button>
        <el-button
          v-else
          type="text"
          size="small"
          :style="scope.row.deletedStatus===1 ? 'color:red': '' "
          :disabled="scope.row.deletedStatus === 2"
          @click.stop="rowDel(scope)"
        >
          删除
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
      title="帖子数据导出"
      :visible.sync="timeSelector"
      :modal-append-to-body="false"
      width="50%"
    >
      <div class="dialogBox">
        <div class="boxItem">
          <p>时间范围：</p>
          <el-date-picker
            v-model="downloadTime"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"/>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="timeSelector = false">取 消</el-button>
        <download-excel
          class="outFileBtn"
          :fields="outFiles"
          name="帖子管理数据.xls"
          :fetch="getAllData"
        >
          <el-button type="primary" @click="timeSelector = false">确认</el-button>
        </download-excel>
      </div>
    </el-dialog>

    <el-dialog title="修改帖子分类" :visible.sync="dialogCategoryVisible"
               :modal-append-to-body="false"
               width="30%"
    >
      <el-select v-model="dialogCategoryType" placeholder="请选择分类">
        <el-option
          size="medium"
          v-for="item in categoryOptions"
          :key="item.dictKey"
          :label="item.dictValue"
          :value="item.dictKey">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCategoryVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCategoryType">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改标签" :visible.sync="dialogTagVisible"
               :modal-append-to-body="false"
               width="30%"
    >
      <el-select style="width: 100%" :multiple="true" v-model="dialogTagType" placeholder="请选择标签">
        <el-option
          size="medium"
          v-for="item in tagOptions"
          :key="item.dictKey"
          :label="item.dictValue"
          :value="item.dictKey">
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTagVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitTagType">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看视频" :visible.sync="showVideoDialog"
               :modal-append-to-body="false"
               width="30%"
    >
      <div class="video-dialog" style="text-align: center">
        <video controls width="400px" height="300px" :src="videoUrl"></video>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showVideoDialog = false">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  adminDeleteDynamic,
  adminSetDynamicBottom,
  adminSetDynamicLabel,
  adminSetDynamicTop,
  adminWithdrawDeletedDynamic,
  articleList,
  articleUpdate,
  articleUpdateCategory,
  ExcellentUpdate,
  sendPeople,
  setArticleVisible
} from "@/api/article";
import {mapGetters} from "vuex";
import {getDictionary} from "@/api/system/dictbiz";
import {getOriginContentStr} from "@/util/util";

export default {
  computed: {
    ...mapGetters(["userInfo", "permission"]),
  },
  data() {
    let query = {}
    if(window.localStorage.getItem("checkedStatus")){
      query = JSON.parse(window.localStorage.getItem("checkedStatus"))
    }

    return {
      dialogCategoryVisible: false,
      dialogCategoryType: '',
      dialogTagType: [],
      categoryOptions: [],
      tagOptions: [],
      idList: [],
      rowIds: "",
      form: {},
      query,
      timeSelector: false,
      downloadTime: [],
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
        categoryId: '',
      },
      option: {
        tip: false,
        selection: true,
        selectable: "",
        reserveSelection: true,
        searchShow: true,
        searchMenuSpan: 6,
        searchSpan: 8,
        index: false,
        menuWidth: 300,
        searchLabelWidth: 120,
        menuAlign: "center",
        align: "center",
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "动态ID",
            prop: "dynamicId",
            search: true,
            span: 24,
            maxlength: 24,
            showWordLimit: true,
            align: "left",
            width: 170
          },
          {
            label: "发布人",
            prop: "username",
            search: true,
            span: 24,
            maxlength: 24,
            showWordLimit: true,
            align: "left",
            width:100,
          },
          {
            label: "动态内容",
            prop: "contentStrReplace",
            align: "left",
            search: true,
            span: 24,
            maxlength: 24,
            showWordLimit: true,
            overHidden: true,
            width: 400,
          },
          {
            label: "发布时间",
            prop: "publishTime",
            span: 24,
            search: true,
            searchRange: true,
            type: "daterange",
            valueFormat: "timestamp",
            startPlaceholder: "日期开始范围",
            endPlaceholder: "日期结束范围",
            pickerOptions: {
              disabledDate: (time) => {
                return time.getTime() > Date.now();
              },
            },
          },
          {
            label: "是否置顶",
            prop: "isTop",
            search: true,
            type: "select",
            dicData: [
              {value: 1, label: "是"},
              {value: 0, label: "否"},
            ],
          },
          {
            label: "是否置底",
            prop: "bottomSort",
            search: true,
            type: "select",
            dicData: [
              {value: 1, label: "是"},
              {value: 0, label: "否"},
            ],
          },
          {
            label: "图片/视频",
            prop: "imageList",
            slot: "imageList",
            type: "upload",
            search: false,
          }, {
            label: "所属部门",
            prop: "deptStr",
            type: "input",
            search: false,
          },

          {
            label: "审核状态",
            type: "select",
            prop: "checkedStatus",
            dataType: 'number',
            searchDefault: 1,
            search: true,
            dicUrl: "/api/oa-system/dict-biz/dictionary?code=dynamic_checked_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
          },
          {
            label: "删除状态",
            prop: "deletedStatus",
            dataType: 'number',
            dicUrl: "/api/oa-system/dict-biz/dictionary?code=dynamic_deleted_status",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            search: true,
            type: "select",
          },
          {
            label: "板块",
            prop: "sectionTitle",
            search: true,
            dicUrl: "/api/oa-bbs/section/adminListAll",
            props: {
              label: "title",
              value: "code"
            },
            type: "select"
          },
        ],
      },
      data: [],
      dialogTagVisible: false,
      tempTagItem: {},
      showVideoDialog: false,
      videoUrl: ''
    };
  },
  methods: {
    async changeCategoryType(row) {
      let res = await getDictionary({"code": "article_type"})
      this.dialogCategoryVisible = true
      this.categoryOptions = res.data.data
      this.tempCategoryItem = row;
    },
    async addDynamicTag(row) {
      let res = await getDictionary({"code": "dynamic_label"})
      this.dialogTagVisible = true
      this.dialogTagType = row.labelList.map(item => "" + item);
      this.tagOptions = res.data.data
      this.tempTagItem = row;
    },
    submitCategoryType() {
      // 添加分类成功，
      if (this.tempCategoryItem.categoryId !== this.dialogCategoryType) {
        articleUpdateCategory({
          id: this.tempCategoryItem.id,
          categoryId: this.dialogCategoryType
        }).then(async res => {
          this.$message.success("修改成功")
          this.dialogCategoryVisible = false
          this.data = await this.onLoad(this.page);
        })
        // 换回首页
      } else {
        this.$message.warning("不能和当前类相同！")
      }
    },
    submitTagType() {
      adminSetDynamicLabel({
        dynamicId: this.tempTagItem.dynamicId,
        labelList: this.dialogTagType
      }).then(async res => {
        this.dialogTagType = '';
        this.$message.success("修改成功")
        this.dialogTagVisible = false
        this.data = await this.onLoad(this.page);
      })
    },
    getAllData() {
      return this.onLoad({...this.page, pageSize: -1}, this.query);
    },
    async loadData(page, params = {}) {
      this.data = await this.onLoad(page, params);
    },
    onLoad(page, params = {}) {
      return new Promise((resolve) => {
        this.loading = true;
        const [startTime, endTime] = this.downloadTime;
        Object.assign(params, this.query);
        if(params.contentStrReplace){
          params.content = params.contentStrReplace
        }
        if(params.sectionTitle){
          params.sectionCode = params.sectionTitle
        }
        if(params.publishTime){
          params.startPublishTime = params.publishTime[0]
          params.endPublishTime = params.publishTime[1]
        }
        if(params.bottomSort !== undefined){
          params.isBottom = params.bottomSort
        }
        articleList({
          query: {
            current: page.currentPage,
            size: page.pageSize,
          },
          startTime,
          endTime,
          ...params

        }).then((res) => {
          if (res) {
            this.loading = false;
            this.page.total = res.data.data.total;
            let maxContentStr = 30
            // contentStr
            resolve(
              res.data.data.records.map((item, index) => {
                let contentStr = getOriginContentStr(item.content)
                let contentStrReplace = contentStr
              /*  if(contentStr.length > maxContentStr){
                  contentStrReplace = contentStr.substring(0,maxContentStr) + "...."
                }*/
                return {
                  index: index + 1,
                  ...item,
                  isTop: item.topSort > 0 ? `已置顶—${item.topSort}` : "未置顶",
                  contentStr,
                  contentStrReplace,
                  imgUrlList: item.imageList
                };
              })
            );
          }
        });
      });
    },
    async searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      if(params.checkedStatus !== undefined){
        window.localStorage.setItem("checkedStatus", JSON.stringify({
          checkedStatus: params.checkedStatus
        }))
      }
      this.data = await this.onLoad(this.page, params);
      done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    async refreshChange() {
      this.data = await this.onLoad(this.page, this.query);
    },
    async searchReset() {
      this.query = {};
      window.localStorage.removeItem("checkedStatus")
      this.data = await this.onLoad(this.page);
    },
    downloadExcel() {
      this.timeSelector = true;
      this.downloadTime = [];
    },
    rowView(row = {}) {
      const {dynamicId} = row.row || {};

      this.$router.push({name: "articleInfo", query: {dynamicId}});
    },
    rowHot(row) {
      this.$confirm("此操作将该帖子修改热帖状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          let hotType;
          if (row.row.hotType === 0) {
            hotType = 1;
          } else {
            hotType = 0;
          }
          let params = {id: row.row.id, hotType};
          let {data} = await articleUpdate(params);
          this.loading = false;
          if (data.code === 200) {
            if (hotType === 0) {
              this.$message.success("取消热帖成功!");
            } else {
              this.$message.success("设为热帖成功!");
            }
            this.data = await this.onLoad(this.page);
          } else {
            if (hotType === 0) {
              this.$message.error("取消热帖失败");
            } else {
              this.$message.error("设为热帖失败");
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    rowTop(row) {
      this.$prompt("置顶请输入数字 0-99（ 0为取消置顶 ）", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[0-9]{1,2}?$/,
        inputErrorMessage: "置顶请输入数字 0-99",
      })
        .then(async ({value}) => {
          this.loading = true;
          let params = {serialNum: value, dynamicId: row.row.dynamicId};
          let {data} = await adminSetDynamicTop(params);
          this.loading = false;
          if (data.code === 200) {
            if (value === 0) {
              this.$message.success("取消置顶成功");
            } else {
              this.$message.success("设为置顶成功");
            }
          } else {
            if (value === 0) {
              this.$message.success("取消置顶失败");
            } else {
              this.$message.success("设为置顶失败");
            }
          }
          this.data = await this.onLoad(this.page);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setArticleVisible(data) {
      const permissionScope = data.permissionScope;
      setArticleVisible({id: data.id, permissionScope: 1 - permissionScope}).then(async () => {
        const msg = permissionScope ? '设为默认可见成功' : '设为全部可见成功';
        this.data = await this.onLoad(this.page);
        this.$message.warning(msg);
      }).catch(() => {
        this.$message.warning('设置文章可见失败')
      })
    },
    rowBottom(data) {
      const {bottomSort, dynamicId} = data
      const text = bottomSort === 0 ? '设为' : '取消'
      this.$confirm(`是否${text}置底?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          let params = {serialNum: bottomSort === 0 ? 1 : 0, dynamicId};
          let {data} = await adminSetDynamicBottom(params);
          this.loading = false;
          if (data.code === 200) {
            if (bottomSort === 0) {
              this.$message.success("设为置底成功");
            } else {
              this.$message.success("取消置底成功");
            }
          } else {
            if (bottomSort === 0) {
              this.$message.success("设为置底失败");
            } else {
              this.$message.success("取消置底失败");
            }
          }
          this.data = await this.onLoad(this.page);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    rowExcellent(row) {
      this.rowIds = row.row.id;
      const {isExcellent} = row.row
      this.$confirm("此操作将该帖子修改优质帖状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          ExcellentUpdate(this.rowIds).then(async (res) => {
            if (res.data.code === 200) {
              if (isExcellent === 1) {
                this.$message.success("取消优质帖成功!");
              } else {
                this.$message.success("设为优质帖成功!");
              }
              this.data = await this.onLoad(this.page);
            } else {
              if (isExcellent === 0) {
                this.$message.error("取消优质帖失败");
              } else {
                this.$message.error("设为优质帖失败");
              }
            }
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    rowDel(row) {
      this.$confirm("此操作将删除该帖子, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          let {data} = await adminDeleteDynamic({dynamicId: row.row.dynamicId});
          this.loading = false;
          if (data.code === 200) {
            this.$message.success("删除成功!");
            this.data = await this.onLoad(this.page);
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    rowRollbackDel(row){
      this.$confirm("此操作见恢复动态展示, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          let {data} = await adminWithdrawDeletedDynamic({dynamicId: row.row.dynamicId});
          this.loading = false;
          if (data.code === 200) {
            this.$message.success("插销成功!");
            this.data = await this.onLoad(this.page);
          } else {
            this.$message.error("插销失败");
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message.info("已取消插销");
        });
    },
    //多选的事件
    selectionChange(list) {
      console.log(list);
      let Obj = list.map(function (m) {
        return m.id;
      });
      this.idList = Obj;
    },
    //发送信息
    sendMessage() {
      sendPeople(this.idList).then((res) => {
        if (res.data.code === 200) {
          this.$message.success({
            message: "发送中奖消息成功",
            center: true,
          });
        } else {
          this.$message.error({
            message: "发送中奖消息失败",
            center: true,
          });
        }
      });
      this.$refs.crud.toggleSelection();
    },
    handleVideoClick(videoUrl) {
      this.showVideoDialog = true;
      this.videoUrl = videoUrl
    }
  },
};
</script>
<style lang="scss" scoped>
.imgs-wrap {
  max-width: 100%;
  display: flex;
  overflow-x: auto;
  justify-content: center;
  align-items: center;
  background-color: #e1e1e1;
  .el-image {
    background: #e1e1e1;
    width: 50px;
    max-height: 50px;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 5px;
    ::v-deep .el-image__preview {
      width: 100%;
      height: unset !important;
    }
  }
}
.video-wrap {
  .video {
    cursor: pointer;
  }
}

.dialogBox {
  width: 60%;
  margin: 0 auto;

  .boxItem {
    margin: 20px 0;
    display: flex;

    p {
      display: inline-block;
      text-align: right;
      width: 85px;
      margin: 8px 0;
    }
  }

  .dialog-footer {
    display: flex;
  }
}

.outFileBtn {
  display: inline-block;
  margin-left: 15px;
}
</style>
