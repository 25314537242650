var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "infinite-scroll",
          rawName: "v-infinite-scroll",
          value: _vm.loadComment,
          expression: "loadComment"
        }
      ],
      staticClass: "container"
    },
    [
      _c("el-page-header", {
        attrs: { content: "帖子详情" },
        on: { back: _vm.goBack }
      }),
      _c("div", { staticClass: "Container" }, [
        _c(
          "div",
          { staticClass: "userInfo" },
          [
            _c("el-avatar", {
              staticStyle: { border: "1px solid #aaa" },
              attrs: {
                src:
                  _vm.articleInfo.avatar ||
                  "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              }
            }),
            _c("div", { staticStyle: { "margin-left": "10px" } }, [
              _c("p", [_vm._v(_vm._s(_vm.articleInfo.username))]),
              _c("p", [_vm._v(_vm._s(_vm.articleInfo.publishTime))])
            ])
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "mainContent",
            domProps: { innerHTML: _vm._s(_vm.articleInfo.content) }
          },
          [_vm._v("}")]
        ),
        _vm.articleInfo.imageList && _vm.articleInfo.imageList.length > 0
          ? _c("div", { staticClass: "carouselList" }, [
              _c(
                "div",
                { staticClass: "imageList" },
                _vm._l(_vm.articleInfo.imageList, function(item, index) {
                  return _c("el-image", {
                    key: index,
                    class: item ? "activeImage" : "normalImage",
                    attrs: {
                      src: item,
                      fit: "contain",
                      "preview-src-list": _vm.articleInfo.imageList
                    },
                    on: {
                      click: function($event) {
                        return _vm.imageClick(index)
                      }
                    }
                  })
                }),
                1
              )
            ])
          : _vm._e(),
        _vm.articleInfo.videoUrl
          ? _c("div", { staticClass: "videoList" }, [
              _c("video", {
                attrs: { src: _vm.articleInfo.videoUrl, controls: "" }
              })
            ])
          : _vm._e(),
        _c("div", { staticClass: "relation" }, [
          _c("div", { staticClass: "flex" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.articleInfo.adminDynamicStatisticVO.browseCount) +
                  " 浏览"
              )
            ])
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm.articleInfo.adminDynamicStatisticVO.shareCount) +
                  " 分享"
              )
            ])
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("p", [
              _c("i", { staticClass: "el-icon-thumb" }),
              _vm._v(
                _vm._s(_vm.articleInfo.adminDynamicStatisticVO.thumbsUpCount) +
                  "\n        "
              )
            ])
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("p", [
              _c("i", { staticClass: "el-icon-star-off" }),
              _vm._v(
                _vm._s(_vm.articleInfo.adminDynamicStatisticVO.forwardCount) +
                  "\n        "
              )
            ])
          ]),
          _c("div", { staticClass: "flex" }, [
            _c("p", [
              _c("i", { staticClass: "el-icon-chat-dot-square" }),
              _vm._v(
                _vm._s(
                  _vm.articleInfo.adminDynamicStatisticVO.totalCommentCount
                ) + "\n        "
              )
            ])
          ])
        ]),
        _vm.allStatus
          ? _c(
              "div",
              { staticClass: "CheckStatusBtn" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "150px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.Approved }
                  },
                  [_vm._v("审核通过")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "80px" },
                    on: { click: _vm.noApproved }
                  },
                  [_vm._v("审核驳回")]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "comment" },
        [
          _vm._m(0),
          _vm._l(_vm.commentList, function(item, index) {
            return _c(
              "comment-item",
              _vm._b(
                {
                  key: index,
                  attrs: {
                    commentList: item.commentReplyVOs
                      ? item.commentReplyVOs.records
                      : []
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleDeleteComment(index, $event)
                    }
                  }
                },
                "comment-item",
                item,
                false
              )
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("i", { staticClass: "el-icon-chat-dot-square" }),
      _c("span", [_vm._v("评论")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }