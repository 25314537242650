<!--suppress ALL -->
<template>
  <!-- table component -->
  <div class="table">
    <el-table ref="multipleTable" :data="tableData" border :row-style="rowStyle" @selection-change="handleSelectionChange" style="width: 100%">
      <el-table-column v-if="Checkbox" type="selection" width="80" align="center"></el-table-column>
      <el-table-column v-if="index" label="序号" prop="index" width="80" align="center">
        <template slot-scope="scope">
          <div>
            <span v-if="tableData[scope.$index].index !== '合计'&&tableData[0].index === '合计'">{{
                ((pageSetting.current - 1) * pageSetting.pageSize) + scope.$index
              }}</span>
            <span v-else-if="'合计'=== tableData[scope.$index].index">合计</span>
            <span v-else>{{ ((pageSetting.current - 1) * pageSetting.pageSize) + scope.$index + 1 }}</span>
          </div>
        </template>
      </el-table-column>
      <!--   表格绘制   -->
      <el-table-column v-for="(item, index) in tableHeader"
                       :prop="item.prop"
                       :label="item.label"
                       :width="item.width"
                       :show-overflow-tooltip="item.showTips"
                       :key="index" align="center">
        <template slot-scope="scope">
          <slot name="option" :data="scope" v-if="item.prop==='option'"></slot>
          <span v-if="item.prop!=='option'&& item.type !== 'images'">{{ scope.row[item.prop] }}</span>
          <span v-if="item.type==='images'">
            <el-image @click="openSrc(scope)" :src="scope.row[item.prop]"></el-image>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <!--   分页配置   -->
    <div class="page" v-show="pageShow">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, sizes, jumper"
        :page-sizes="[10, 20, 50]"
        :current-page="pageSetting.current"
        :page-size="pageSetting.pageSize"
        :total="pageSetting.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Boolean,
      default: () => {return true}
    },
    Checkbox: {
      type: Boolean,
      default: () => {return false}
    },
    tableData: {
      type: Array,
      default: () => {return Array}
    },
    tableHeader: {
      type: Array,
      default: () => {return Array}
    },
    type: {
      type: String,
      default: () => {return String}
    },
    pageShow: {
      //分页的显示
      type: Boolean,
      default: () => {return true}
    },
    // 操作显隐
    optShow: {
      type: Boolean,
      default: () => {return true}
    },
    pageSetting: {
      type: Object,
      default: () => {return Object}
    },
  },
  methods: {
    // 分页大小改变
    handleSizeChange (size) {
      this.$emit('sizeChange', size)
    },
    // 分页current page change
    handleCurrentChange (page) {
      this.$emit('pageChange', page)
    },
    // 点击图片时间
    openSrc ( row ) {
      this.$emit('srcHandleClick', row)
    },
    // row style
    rowStyle ({ row }) {
      if (row.surveyStatus === 'A') {
        return {
          color: 'red'
        }
      }
    },
    handleSelectionChange (val) {
      this.$emit('handleSelectionChange', val)
    }
  }
}
</script>

<style scoped>
.table .page {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.cell_smoke {
  display: flex;
  align-items: center;
}

.cell_smoke .lineBox {
  height: 20px;
  background-color: #409eff;
  margin-right: 4px;
}

.cell_smoke .lineBoxFalse {
  height: 20px;

  background-color: red;
}

.cell_smoke .lineBox span {
  height: 20px;
  line-height: 20px;
}
</style>
