<template>
  <div>
    <el-row>
      <el-col :span="5">
        <div class="box">
          <el-scrollbar>
            <basic-container>
              <avue-tree
                :option="treeOption"
                :data="treeData"
                @node-click="nodeClick"
              />
            </basic-container>
          </el-scrollbar>
        </div>
      </el-col>
      <el-col :span="19">
        <basic-container>
          <avue-crud
            :option="option"
            :table-loading="loading"
            :data="data"
            ref="crud"
            v-model="form"
            :page.sync="page"
            @search-change="searchChange"
            @search-reset="searchReset"
            @selection-change="selectionChange"
            @current-change="currentChange"
            @size-change="sizeChange"
            @refresh-change="refreshChange"
            @on-load="onLoad"
          >
            <div slot="menuLeft" />

            <template slot="avatar" slot-scope="scope">
              <div class="imgs-wrap" v-if="scope.row.avatar">
                <el-image
                  :key="scope.row.avatar"
                  :src="scope.row.avatar"
                  :preview-src-list="[scope.row.avatar]"
                >
                </el-image>
              </div>
            </template>
          </avue-crud>
        </basic-container>
      </el-col>
    </el-row>
    <el-dialog
      title="选择发言人"
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true"
    >
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选
      </el-checkbox>
      <div style="margin: 15px 0"></div>
      <el-checkbox-group
        v-model="checkedCities"
        @change="handleCheckedCitiesChange"
      >
        <el-checkbox v-for="item in nameList" :label="item" :key="item"
          >{{ item.name }}
        </el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getGeelyUserList,
  setSuserUserSenior,
  setSuserUserSuperAdmin,
} from "@/api/suser";
import { getDeptLazyTree } from "@/api/system/dept";
import { mapGetters } from "vuex";
import website from "@/config/website";

export default {
  data() {
    return {
      checkedCities: [],
      checkAll: false,
      speakManId: [],
      isIndeterminate: false,
      nameList: [],
      userId: "",
      form: {},
      roleBox: false,
      excelBox: false,
      platformBox: false,
      initFlag: true,
      selectionList: [],
      query: {},
      loading: true,
      platformLoading: false,
      dialogVisible: false,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      listQueryParams: {
        size: 10,
        current: 1,
        total: 0,
      },
      //下拉框的数据
      options: [
        {
          value: "zhinan",
          label: "指南",
        },
        {
          value: "zhinan",
          label: "指南",
        },
        {
          value: "zhinan",
          label: "指南",
        },
      ],
      init: {
        roleTree: [],
        deptTree: [],
      },
      props: {
        label: "title",
        value: "key",
      },
      roleGrantList: [],
      roleTreeObj: [],
      treeDeptId: "",
      treeData: [],
      treeOption: {
        nodeKey: "id",
        lazy: true,
        treeLoad: function (node, resolve) {
          const parentId = node.level === 0 ? 0 : node.data.id;
          getDeptLazyTree(parentId).then((res) => {
            resolve(
              res.data.data.map((item) => {
                return {
                  ...item,
                  leaf: !item.hasChildren,
                };
              })
            );
          });
        },
        addBtn: false,
        menu: false,
        size: "small",
        search: false,
        props: {
          labelText: "标题",
          label: "title",
          value: "value",
          children: "children",
        },
      },
      option: {
        height: "auto",
        calcHeight: 80,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        menu: false,
        selection: false,
        viewBtn: false,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        indexLabel: "序号",
        dialogClickModal: false,
        column: [
          {
            label: "头像",
            prop: "avatar",
            width: "80px",
            slot: "topicImage",
            type: "upload",
            listType: "picture-img",
            span: 24,
            propsHttp: {
              url: "link",
            },
            tip: "只能上传jpg/png主题图，且不超过500kb",
            action: "/api/oa-resource/oss/endpoint/put-file",
          },
          {
            label: "姓名",
            prop: "nameDisplay",
            search: true,
          },
          {
            label: "工号",
            prop: "empNo",
            search: true,
          },
          {
            label: "用户手机号",
            prop: "phone",
          },
          {
            label: "用户邮箱",
            prop: "email",
          },
          {
            label: "所属公司",
            prop: "deptStr",
          },
        ],
      },
      data: [],
    };
  },
  watch: {},
  computed: {
    ids() {
      let ids = [];
      this.selectionList = this.selectionList || [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    // 非租户模式默认加载管理组数据
    if (!website.tenantMode) {
      this.initData(website.tenantId);
    }
  },
  created() {
    // getSpeakMan(this.userId).then((res) => {initData
    //   this.nameList = res.data.data.records;
    // });
  },
  methods: {
    async setSenior({ id } = {}) {
      await setSuserUserSenior(id);
      this.onLoad(this.page);
    },
    async setSuperAdmin({ id, adminType } = {}) {
      let superAdmin = 0;
      if (adminType) {
        superAdmin = adminType;
      }
      await setSuserUserSuperAdmin({ id, superAdmin });
      this.onLoad(this.page);
    },
    //复选框的事件
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.nameList : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.nameList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.nameList.length;
      let Obj = value.map(function (m) {
        return m.id;
      });
      this.speakManId = Obj;
    },
    nodeClick(data) {
      this.treeDeptId = data.id;
      this.page.currentPage = 1;
      this.onLoad(this.page, { deptId: this.treeDeptId });
    },
    initData(tenantId) {},
    searchReset() {
      this.query = {};
      this.treeDeptId = "";
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
      // this.onLoad(this.page, {deptId: this.treeDeptId});
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      const { currentPage: pageNum, pageSize } = page;
      const vm = this;
      const obj = {
        pageNum,
        pageSize,
        ...Object.assign(params, this.query),
      };
      if (vm.treeDeptId) {
        obj["deptId"] = vm.treeDeptId;
      }
      getGeelyUserList(obj).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },
  },
};
</script>
<style>
.box {
  height: 800px;
}

.el-scrollbar {
  height: 100%;
}

.box .el-scrollbar__wrap {
  overflow: scroll;
}
</style>
<style lang="scss" scoped>
/deep/.el-table__body-wrapper {
  overflow-y: auto;
  // background-color: red;
}
</style>

</style>