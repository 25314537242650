<template>
  <div ref="editor" class="editor" id="editor"></div>
</template>

<script>
import E from "wangeditor";

export default {
  name: "editoritem",
  props: {
    meanArray: {
      // 自定义菜单
      type: Array,
      default: null,
    },
  },
  model: {
    prop: "value",
    event: "change",
  },

  data() {
    return {
      // 默认有这么多菜单，meanArray有值以meanArray为准
      defaultMeanus: [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "italic",
        "underline",
        "strikeThrough",
        "indent",
        "lineHeight",
        "foreColor",
        "backColor",
        "link",
        "list",
        "justify",
        "quote",
        "emoticon",
        "image",
        "video",
        "table",
        "splitLine",
        "undo",
        "redo",
      ],
      editor: "",
    };
  },
  methods: {
    init() {
      this.editor = new E(this.$refs.editor);
      this.editor.config.uploadImgShowBase64 = true; // 使用 base64 保存图片
      // this.editor.config.pasteFilterStyle = false  //关闭样式过滤。

      this.editor.config.customUploadImg = async (resultFiles, insertImgFn) => {
        // resultFiles 是 input 中选中的文件列表
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        const res = await this.$upload.multipleFilesUpload(resultFiles);
        loading.close();
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        // 上传图片，返回结果，将图片插入到编辑器中
        res.forEach(item => {
          insertImgFn(item.filename)
        })
      }

      // 自定义检查插入视频的回调
      this.editor.config.customUploadVideo = async (resultFiles,insertVideoFn) => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        const res = await this.$upload.fileUpload(resultFiles[0]);
        loading.close();
        insertVideoFn(res.filename)
      }

      this.setMenus(); //设置菜单
      this.editor.create(); //创建编辑器
    },
    setMenus() {
      // 设置菜单
      if (this.meanArray) {
        this.editor.config.menus = this.meanArray;
      } else {
        this.editor.config.menus = this.defaultMeanus;
      }
    },
    getHtml() {
      console.log("获取内容为：", this.editor.txt.html())
      return this.editor.txt.html();
    },
    setHtml(txt) {
      // 设置富文本里面的值
      console.log("设置值到editor中。。。", txt)
      this.editor.txt.html(txt);
    },
  },
  created() {
    let that = this;
    that.$nextTick(function () {
      that.init();
    });
  },
};
</script>
<style lang="css" scoped>
.editor {
  width: 90%;
  margin: 0 auto;
  /*position: relative;*/
  z-index: 0;
}

.toolbar {
  border: 1px solid #ccc;
}

.text {
  border: 1px solid #ccc;
  min-height: 500px;
}
</style>
