<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      v-model="form"
      :page.sync="page"
      @search-change="searchChange"
      @search-reset="searchReset"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft">
        <el-button type="primary" size="small" @click.stop="handleAdd">新增</el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button type="text" size="small" @click.stop="handleEdit(scope.row)">编辑</el-button>
        <el-button type="text" size="small" style="color:red" @click.stop="rowDel(scope.row)">删除</el-button>
      </template>
      <template slot="types" slot-scope="{row}">
        <el-tag style="margin-right: 10px" v-for="(item, index) in computeTypes(row.$types)" :key="index">{{
            item
          }}
        </el-tag>
      </template>
    </avue-crud>
    <el-dialog :title="addForm.edit ? '修改发言人': '新增发言人'"
      append-to-body
      :visible.sync="addPublishDialog"
      width="40%">
      <el-form ref="addForm" :model="addForm" :rules="rules" label-width="80px">
        <el-form-item label="姓名搜索" prop="username">
          <el-select v-model="addForm.username" placeholder="请选择"
            filterable
            remote
            reserve-keyword
            :remote-method="remoteSearchByUserName"
            :loading="loading"
            width="200px"
            :disabled="addForm.edit"
          >
            <el-option
              v-for="item in usernameOptions"
              :key="item.id"
              :label="item.nameDisplay"
              :value="item.id">
              <span style="float: left">{{ item.nameDisplay }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptStr }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="赋予权限" prop="types">
          <el-checkbox-group v-model="addForm.types">
            <el-checkbox :label="item.dictKey" :key="item.dictKey" v-for="item in dictData">{{ item.dictValue }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
                <el-button @click="addPublishDialog = false">取 消</el-button>
                <el-button type="primary"
                  @click="handleSubmit">确 定</el-button>
              </span>
    </el-dialog>

  </basic-container>
</template>

<script>
import {contentPublishPermissionList, contentPublishPermissionRemove, saveOrUpdate} from "@/api/speak";
import {listGeelyUser} from "@/api/system/geelyuser";
import {getDictionary} from "@/api/system/dictbiz";
import {remove} from "@/api/system/menu";

let addForm = {
  username: '',
  types: [],
}

export default {
  data() {
    return {
      form: {},
      query: {},
      addForm: JSON.parse(JSON.stringify(addForm)),
      addFormRules: {},
      usernameOptions: [],
      addPublishDialog: false,
      loading: true,
      dictData: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      option: {
        searchShow: true,
        searchMenuSpan: 6,
        searchSpan: 8,
        index: true,
        indexLabel: "序号",
        menuWidth: 300,
        searchLabelWidth: 120,
        menuAlign: "center",
        align: "center",
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "发言人",
            prop: "username",
            search: true,
            span: 24,
            maxlength: 24,

          },
          {
            label: "工号",
            prop: "empNo",
            search: true,
            span: 24,
          },
          {
            label: "部门",
            prop: "deptStr",
            span: 24,
          }, {
            label: "权限类型",
            dataType: 'number',
            prop: "types",
            type: "select",
            search: false,
            slot: true,
            dicUrl: "/api/oa-system/dict-biz/dictionary?code=content_publish_permission",
            props: {
              label: "dictValue",
              value: "dictKey"
            },
          }, {
            label: "修改人",
            prop: "updateUsername",
            span: 24,
          },
          {
            label: "修改时间",
            prop: "updateTime",
            span: 24,
          },
        ],
      },
      rules: {
        username: [
          {required: true, message: '请选择用户', trigger: 'blur'},
        ]
      },
      data: [],
    };
  },
  created() {
    getDictionary({"code": "content_publish_permission"}).then(res => {
      this.dictData = res.data.data
    })
  },
  methods: {
    rowDel(row, index, done) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return contentPublishPermissionRemove({id: row.id});
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          // 数据回调进行刷新
          this.page.currentPage = 1;
          this.onLoad(this.page)
          done(row);
        });
    },
    computeTypes(str) {
      if (str) {
        return str.split(" | ")
      }
      return str
    },
    handleAdd() {
      if (this.dictData) {
        let ids = this.dictData.map(item => item.dictKey)
        this.addForm = JSON.parse(JSON.stringify(addForm))
        this.addForm.types = ids;
        this.addPublishDialog = true;
      }
    },
    handleEdit(row) {
      this.tempRow = row;
      if (this.dictData) {
        this.addForm = JSON.parse(JSON.stringify(addForm))
        this.addForm.username = row.username;
        this.addForm.edit = true;
        if (row.types) {
          this.addForm.types = row.types.map(item => "" + item);
        }
        this.addPublishDialog = true;
      }
    },
    getAllData() {
      return this.onLoad(this.page, this.query);
    },

    onLoad(page, params = {}) {
      this.loading = true;
      contentPublishPermissionList({
        pageNum: page.currentPage,
        pageSize: page.pageSize,
        ...Object.assign(params, this.query),
      }).then((res) => {
        if (res) {
          this.loading = false;
          this.page.total = res.data.data.total;
          this.data = res.data.data.records.map((item) => {
            let typesStrArr = item.types;
            return {
              ...item,
              typesStrArr,
            };
          })
        }
      });
    },
    //远程数据搜索
    remoteSearchByUserName(query) {
      if (query !== '') {
        this.searchLoading = true;
        listGeelyUser({nameDisplay: query}).then(res => {
          this.searchLoading = false;
          this.usernameOptions = res.data.data.records
        }).catch(() => {
          this.$message.error("搜索失败！")
          this.searchLoading = false;
        })
      } else {
        this.usernameOptions = [];
      }
    },
    async searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.data = await this.onLoad(this.page, params);
      done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    async refreshChange() {
      this.onLoad(this.page, this.query);
    },
    async searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    handleSubmit() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          let form = {
            types: this.addForm.types = this.addForm.types.map(item => parseInt(item))
          }
          if (!this.addForm.edit) {
            form.userId = this.addForm.username
          } else {
            form.id = this.tempRow.id;
          }
          saveOrUpdate(form).then(() => {
            this.addPublishDialog = false;
            this.page.currentPage = 1;
            this.onLoad(this.page)
          })
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialogBox {
  width: 60%;
  margin: 0 auto;

  .boxItem {
    margin: 20px 0;
    display: flex;

    p {
      display: inline-block;
      text-align: right;
      width: 85px;
      margin: 8px 0;
    }
  }
}

.mainBox {
  .boxItem {
    background: #eff2f5;

    .boxTop {
      margin-left: 20px;
      display: flex;

      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }

      .details-main {
        margin-left: 10px;
      }
    }

    .title {
      width: 100%;
      min-height: 50px;
      display: inline-block;
      margin-left: 20px;
      margin-top: 20px;
      font-weight: 500;
      font-size: 22px;
    }

    .content {
      width: 100%;
      min-height: 80px;
      margin-top: 30px;
      margin-left: 20px;
    }
  }
}
</style>
