<template>
    <h1>
      优先级较低，暂未开发
    </h1>
</template>

<script>
import {
  articleList,
  articleRemove,
  addVote,
  lookAllPeopleNum,
  updateVote,
  articleDetail,
} from "@/api/article";
import voteDetailed from "./vote-detail.vue";
export default {

}
</script>

