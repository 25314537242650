var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            permission: _vm.permissionList,
            "before-open": _vm.beforeOpen,
            "before-close": _vm.beforeClose
          },
          on: {
            "row-del": _vm.rowDel,
            "row-update": _vm.rowUpdate,
            "row-save": _vm.rowSave,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad,
            "tree-load": _vm.treeLoad
          },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function(scope) {
                return [
                  _vm.userInfo.role_name.includes("admin") &&
                  scope.row.category === 1
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-circle-plus-outline",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handleAdd(scope.row, scope.index)
                            }
                          }
                        },
                        [_vm._v("新增子项\n      ")]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "source",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c("div", { staticStyle: { "text-align": "center" } }, [
                    _c("i", { class: row.source })
                  ])
                ]
              }
            }
          ]),
          model: {
            value: _vm.form,
            callback: function($$v) {
              _vm.form = $$v
            },
            expression: "form"
          }
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _vm.permission.menu_delete
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        size: "small",
                        icon: "el-icon-delete",
                        plain: ""
                      },
                      on: { click: _vm.handleDelete }
                    },
                    [_vm._v("删 除\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }