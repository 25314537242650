<template>
  <el-dialog :append-to-body="true" :modal-append-to-body="false" :visible.sync="visible" title="" width="40%">
    <div class="cropper-content">
      <div class="cropper-box">
        <div class="cropper">
          <vue-cropper
            ref="cropper"
            :autoCrop="option.autoCrop"
            :autoCropHeight="option.autoCropHeight"
            :autoCropWidth="option.autoCropWidth"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :canScale="option.canScale"
            :centerBox="option.centerBox"
            :enlarge="option.enlarge"
            :fixed="option.fixed"
            :fixedBox="option.fixedBox"
            :fixedNumber="option.fixedNumber"
            :full="option.full"
            :height="option.height"
            :img="option.img"
            :info="option.info"
            :infoTrue="option.infoTrue"
            :maxImgSize="option.maxImgSize"
            :mode="option.mode"
            :original="option.original"
            :outputSize="option.outputSize"
            :outputType="option.outputType">
          </vue-cropper>
        </div>
        <!--底部操作工具按钮-->
        <div class="footer-btn">
          <div class="scope-btn">
            <el-button icon="el-icon-zoom-in" plain size="mini" type="danger" @click="changeScale(1)">放大</el-button>
            <el-button icon="el-icon-zoom-out" plain size="mini" type="danger" @click="changeScale(-1)">缩小</el-button>
            <el-button plain size="mini" type="danger" @click="rotateLeft">↺ 左旋转</el-button>
            <el-button plain size="mini" type="danger" @click="rotateRight">↻ 右旋转</el-button>
          </div>
          <div class="upload-btn">
            <el-button size="mini" type="success" @click="uploadImg('blob')">确定</el-button>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {putOssImages} from "../../api/resource/oss";

export default {
  name: "CropperImage",
  props: {
    visible: {type: Boolean, default: false},
    option: {
      img: {type: String, default: ''},   //裁剪图片的地址
      outputSize: {type: Number, default: 1},   //裁剪生成图片的质量(可选0.1 - 1)
      outputType: {type: String, default: 'jpeg'}, //裁剪生成图片的格式（jpeg || png || webp）
      info: {type: Boolean, default: true},//图片大小信息
      canScale: {type: Boolean, default: true},         //图片是否允许滚轮缩放
      autoCrop: {type: Boolean, default: false},      //是否默认生成截图框
      autoCropWidth: {type: Number, default: 230},    //默认生成截图框宽度
      autoCropHeight: {type: Number, default: 150},   //默认生成截图框高度
      fixed: {type: Boolean, default: true},           //是否开启截图框宽高固定比例
      fixedNumber: {
        type: Array, default: () => {
          return [9,5]
        }
      }, //截图框的宽高比例
      full: {type: Boolean, default: false},         //false按原比例裁切图片，不失真
      fixedBox: {type: Boolean, default: true},      //固定截图框大小，不允许改变
      canMove: {type: Boolean, default: false},      //上传图片是否可以移动
      canMoveBox: {type: Boolean, default: true},    //截图框能否拖动
      original: {type: Boolean, default: false},     //上传图片按照原始比例渲染
      centerBox: {type: Boolean, default: false},    //截图框是否被限制在图片里面
      height: {type: Boolean, default: true},        //是否按照设备的dpr 输出等比例图片
      infoTrue: {type: Boolean, default: false},     //true为展示真实输出图片宽高，false展示看到的截图框宽高
      maxImgSize: {type: Number, default: 3000},    //限制图片最大宽度和高度
      enlarge: {type: Number, default: 1},          //图片根据截图框输出比例倍数
      mode: '230px 150px'  //图片默认渲染方式
    }
  },
  data() {
    return {
      fileName: '',
    }
  },
  methods: {
    //图片缩放
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    //向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft()
    },
    //向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight()
    },
    //上传图片
    uploadImg(type) {
      if (type === 'blob') {
        //获取截图的blob数据
        this.$refs.cropper.getCropBlob(async (data) => {
          let formData = new FormData();
          formData.append('file', data)
          putOssImages(formData).then(res => {
            this.$emit('finishCrop',res.data)
          })
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;

  .cropper-box {
    flex: 1;
    width: 100%;

    .cropper {
      width: auto;
      height: 300px;
    }
  }

  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;

    .preview {
      overflow: hidden;
      border: 1px solid #67c23a;
      background: #cccccc;
    }
  }
}

.footer-btn {
  margin-top: 30px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;

  .scope-btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    padding-right: 10px;
  }

  .upload-btn {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
  }

  .btn {
    outline: none;
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    -webkit-transition: .1s;
    transition: .1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #409EFF;
    border-color: #409EFF;
    margin-right: 10px;
  }
}
</style>
