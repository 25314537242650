<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      :page.sync="page"
      v-model="formData"
      @search-change="searchChange"
      @search-reset="searchReset"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot-scope="scope" slot="menu">
        <el-button type="text" size="small" @click.stop="rowUpdate(scope)"
          >修改
        </el-button>
        <el-button
          type="text"
          size="small"
          @click.stop="rowStatus(scope)"
          :style="scope.row.status === 1 ? 'color: red;' : ''"
        >
          {{ scope.row.status === 1 ? "停用" : "启用" }}
        </el-button>
        <el-button
          type="text"
          size="small"
          style="color: red"
          @click.stop="rowDel(scope)"
          >删除
        </el-button>
      </template>
      <template slot-scope="scope" slot="menuLeft">
        <el-button type="primary" size="small" @click.stop="rowSave(scope)"
          >新增
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
      :title="isDialogAdd ? '新增' : '修改'"
      :visible.sync="showDialog"
      :modal-append-to-body="false"
      width="40%"
    >
      <div>
        <span>敏感词内容：</span>
        <el-input
          type="text"
          v-model="form.word"
          maxlength="24"
          show-word-limit
          placeholder="请输入敏感词内容"
          style="width: 80%"
          @input="inputChange"
        ></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="changeData">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  sensitiveAdd,
  sensitiveList,
  sensitiveEnable,
  sensitiveDisable,
  sensitiveRemove,
  sensitiveUpdate,
} from "@/api/sensitive";

export default {
  data() {
    return {
      isDialogAdd: false,
      showDialog: false,
      formData: {},
      form: {
        content: "",
        status: "0",
      },
      imageUrl: "",
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      option: {
        searchLabelWidth: 120,
        searchSpan: 8,
        tip: false,
        searchShow: true,
        index: true,
        indexLabel: "序号",
        menuWidth: 150,
        menuAlign: "center",
        align: "center",
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        searchMenuSpan: 4,
        column: [
          {
            label: "敏感词内容",
            prop: "word",
            search: true,
            span: 30,
            maxlength: 24,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入敏感词内容",
                trigger: "blur",
              },
            ],
          },
          {
            label: "更新人",
            prop: "updateUsername",
            search: true,
            maxlength: 24,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "请输入创建人",
                trigger: "blur",
              },
            ],
          },
          {
            label: "更新时间",
            prop: "updateTime",
            span: 24,
            search: false,
            type: "date",
            valueFormat: "yyyy-mm-dd",
          },
          {
            label: "状态",
            prop: "status",
            search: true,
            dicData: [
              { value: 1, label: "启用" },
              { value: 2, label: "停用" },
            ],
            type: "select",
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "select",
              },
            ],
          },
        ],
      },
      data: [],
    };
  },
  methods: {
    onLoad(page, params = {}) {
      this.loading = true;
      this.showDialog = false;
      sensitiveList({
        query: {
          current: page.currentPage,
          size: page.pageSize,
        },
        ...Object.assign(params, this.query),
      }).then((res) => {
        this.loading = false;
        this.page.total = res.data.data.total;
        this.data = res.data.data.records.map((item) => {
          return {
            ...item,
          };
        });
      });
    },
    searchChange(params, done = () => {}) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    rowSave() {
      this.showDialog = true;
      this.isDialogAdd = true;
      this.form.word = "";
      this.form.status = 0;
    },
    inputChange(e) {
      //强制刷新
      console.log(e);
      this.$forceUpdate();
    },
    rowUpdate(row) {
      this.showDialog = true;
      this.isDialogAdd = false;
      this.form = {
        ...row.row,
      };
    },
    async rowStatus({ row }) {
      let res;
      if (row.status === 1) {
        res = await this.$confirm("确定停用该条检测规则吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      }
      if (!res || res === "confirm") {
        this.loading = true;
        let res;
        let postData = { sensitiveWordId: row.id };
        if (row.status === 1) {
          res = await sensitiveDisable(postData);
        } else {
          res = await sensitiveEnable(postData);
        }
        let { data } = res;
        if (data.code === 200) {
          await this.onLoad(this.page);
          this.$message.success("操作成功");
        }
        this.loading = false;
      } else if (res === "cancel") {
        this.$message.info("已取消停用");
      }
    },
    rowDel(row) {
      this.$confirm("此操作将删除该敏感词, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          let { data } = await sensitiveRemove(row.row.id).finally(() => {
            this.loading = true;
          });
          this.loading = false;
          if (data.code === 200) {
            this.$message.success("删除成功!");
            await this.searchChange();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.$message.info("已取消删除");
        });
    },
    async changeData() {
      let regContent = /^[\u4e00-\u9fa5a-z0-9]+$/i;
      if (!this.form.word) {
        this.$message.warning("请输入敏感词");
        return false;
      } else {
        if (!regContent.test(this.form.word)) {
          this.$message.warning("敏感词只能输入中文、字母、数字");
          return false;
        }
      }
      if (this.isDialogAdd) {
        let { data } = await sensitiveAdd(this.form);
        if (data.code === 200) {
          this.$message.success("新增成功");
          await this.onLoad(this.page);
        } else {
          this.$message.error("新增失败");
        }
      } else {
        this.form.sensitiveWordId = this.form.id;
        let { data } = await sensitiveUpdate(this.form);
        if (data.code === 200) {
          this.$message.success("修改成功");
          await this.onLoad(this.page);
        } else {
          this.$message.error("修改失败");
        }
      }
      // this.form.word = "";
    },
    handleChange({ raw }) {
      const isType = raw.type === "image/jpeg" || raw.type === "image/png";
      if (!isType) {
        this.$message.error("请上传png或者是jpg");
      } else {
        this.form.images = raw;
        this.imageUrl = URL.createObjectURL(raw);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dialogBox {
  width: 60%;
  margin: 0 auto;

  .boxItem {
    margin: 20px 0;
    display: flex;

    p {
      display: inline-block;
      text-align: right;
      width: 85px;
      margin: 8px 0;
    }
  }
}
</style>
