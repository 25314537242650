<template>
  <el-row>
    <basic-container>
      <avue-crud :option="option"
                 :search.sync="search"
                 :table-loading="loading"
                 :data="data"
                 ref="crud"
                 v-model="form"
                 :permission="permissionList"
                 @row-del="rowDel"
                 @row-save="rowSave"
                 :before-open="beforeOpen"
                 :page.sync="page"
                 @search-change="searchChange"
                 @search-reset="searchReset"
                 @selection-change="selectionChange"
                 @current-change="currentChange"
                 @size-change="sizeChange"
                 @refresh-change="refreshChange"
                 @on-load="onLoad">
        <template slot="menuLeft">
          <el-button type="primary"
                     size="small"
                     plain
                     v-if="permission.user_add"
                     icon="el-icon-plus"
                     @click="addUserFromCenter">新增
          </el-button>
          <el-button type="info"
                     size="small"
                     plain
                     v-if="permission.user_role"
                     icon="el-icon-user"
                     @click="handleGrant">角色配置
          </el-button>

          <el-button type="warning"
                     size="small"
                     plain
                     v-if="userInfo.role_name.includes('admin')"
                     icon="el-icon-download"
                     @click="handleExport">全部导出
          </el-button>
        </template>
        <template slot-scope="{row}"
                  slot="deptName">
          <el-tag>{{ row.deptName }}</el-tag>
        </template>


        <template slot-scope="scope" slot="menu">
          <el-button
            type="text"
            size="small"
            @click.stop="handleDisable(scope.row)"
            v-if="userInfo.role_name.includes('admin')"
          >
            {{ scope.row.status === 1 ? '禁用' : '启用' }}
          </el-button>
        </template>
      </avue-crud>

      <el-dialog title="管理员新增"
                 append-to-body
                 :visible.sync="selectUserDialog"
                 width="40%">
        <el-form ref="form" :model="addForm" label-width="150px">
          <el-form-item label="根据姓名搜索">
            <el-autocomplete
              style="width: 90%"
              v-model="addForm.nameDisplay"
              :fetch-suggestions="querySearchNameDisplayAsync"
              placeholder="根据姓名搜索"
              @select="handleNameDisplaySelect"
            >
              <template slot-scope="{ item }">
                <div class="search_name">{{ item.nameDisplay }}</div>
                <span class="search_addr">{{ item.deptStr }}</span>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="根据工号搜索">
            <el-autocomplete
              style="width: 90%"
              v-model="addForm.empNo"
              :fetch-suggestions="querySearchEnmNoAsync"
              placeholder="请输入工号"
              @select="handleEmpNoSelect"
            >
              <template slot-scope="{ item }">
                <div class="search_name">{{ item.nameDisplay }}</div>
                <span class="search_addr">{{ item.deptStr }}</span>
              </template>
            </el-autocomplete>
          </el-form-item>
        </el-form>
        <div class="discript">一次只能绑定一个用户，请不要同时使用工号和姓名添加</div>

        <span slot="footer" class="dialog-footer">
            <el-button @click="selectUserDialog = false">取 消</el-button>
            <el-button type="primary"
                       @click="submitAddUser">确 定</el-button>
          </span>
      </el-dialog>

      <el-dialog title="用户角色配置"
                 append-to-body
                 :visible.sync="roleBox"
                 width="345px">
        <el-tree :data="roleGrantList"
                 show-checkbox
                 check-strictly
                 default-expand-all
                 node-key="id"
                 ref="treeRole"
                 :default-checked-keys="roleTreeObj"
                 :props="props">
        </el-tree>

        <span slot="footer" class="dialog-footer">
            <el-button @click="roleBox = false">取 消</el-button>
            <el-button type="primary"
                       @click="submitRole">确 定</el-button>
          </span>
      </el-dialog>


      <el-dialog title="用户数据导入"
                 append-to-body
                 :visible.sync="excelBox"
                 width="555px">
        <avue-form :option="excelOption" v-model="excelForm" :upload-after="uploadAfter">
          <template slot="excelTemplate">
            <el-button type="primary" @click="handleTemplate">
              点击下载<i class="el-icon-download el-icon--right"></i>
            </el-button>
          </template>
        </avue-form>
      </el-dialog>
    </basic-container>
  </el-row>
</template>

<script>
import {
  getList,
  getUser,
  remove,
  add,
  grant,
  update,
  resetPassword, unlock, addFromGeelyUserCenter
} from "@/api/system/user";
import {getDeptLazyTree} from "@/api/system/dept";
import {getRoleTree} from "@/api/system/role";
import {mapGetters} from "vuex";
import {getToken} from '@/util/auth';
import {listGeelyUser} from "@/api/system/geelyuser";

let addForm = {
  empNo: '',
  nameDisplay: '',
  userId: '',
};

export default {
  data() {
    return {
      addForm: JSON.parse(JSON.stringify(addForm)),
      form: {},
      search: {},
      roleBox: false,
      excelBox: false,
      initFlag: true,
      selectionList: [],
      query: {},
      loading: true,
      platformLoading: false,
      selectUserDialog: false,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },

      init: {
        roleTree: [],
        deptTree: [],
      },
      props: {
        label: "title",
        value: "key"
      },
      roleGrantList: [],
      roleTreeObj: [],
      treeDeptId: '',
      treeData: [],
      treeOption: {
        nodeKey: 'id',
        lazy: true,
        treeLoad: function (node, resolve) {
          const parentId = (node.level === 0) ? 0 : node.data.id;
          getDeptLazyTree(parentId).then(res => {
            resolve(res.data.data.map(item => {
              return {
                ...item,
                leaf: !item.hasChildren
              }
            }))
          });
        },
        addBtn: false,
        menu: false,
        size: 'small',
        props: {
          labelText: '标题',
          label: 'title',
          value: 'value',
          children: 'children'
        }
      },
      option: {
        height: 'auto',
        calcHeight: 80,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        selection: true,
        viewBtn: false,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        dialogType: 'drawer',
        dialogClickModal: false,
        column: [
          {
            label: "工号",
            prop: "empNo",
            search: true,
            display: false
          },
          {
            label: "用户姓名",
            prop: "realName",
            search: true,
            display: false
          },
          {
            label: "所属角色",
            prop: "roleName",
            display: false
          },
          {
            label: "所属部门",
            prop: "deptName",
            slot: true,
            display: false
          },

        ],
        group: [
          {
            label: '从用户中心添加人员',
            prop: 'dutyInfo',
            icon: 'el-icon-s-custom',
            span: 24,
            column: [
              {
                label: "工号添加",
                prop: "empno",
                multiple: false,
                type: "tree",
                dicData: [],
                props: {
                  label: "title"
                },
                checkStrictly: true,
                slot: true,
                rules: [{
                  required: true,
                  message: "请选择所属角色",
                  trigger: "click"
                }]
              },
            ]
          },
        ]
      },
      data: [],
      excelForm: {},
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: '模板上传',
            prop: 'excelFile',
            type: 'upload',
            drag: true,
            loadText: '模板上传中，请稍等',
            span: 24,
            propsHttp: {
              res: 'data'
            },
            tip: '请上传 .xls,.xlsx 标准格式文件',
            action: "/api/oa-user/import-user"
          },
          {
            label: "数据覆盖",
            prop: "isCovered",
            type: "switch",
            align: "center",
            width: 80,
            dicData: [
              {
                label: "否",
                value: 0
              },
              {
                label: "是",
                value: 1
              }
            ],
            value: 0,
            slot: true,
            rules: [
              {
                required: true,
                message: "请选择是否覆盖",
                trigger: "blur"
              }
            ]
          },
          {
            label: '模板下载',
            prop: 'excelTemplate',
            formslot: true,
            span: 24,
          }
        ]
      }
    };
  },
  watch: {
    'form.tenantId'() {
      if (this.form.tenantId !== '' && this.initFlag) {
        this.initData(this.form.tenantId);
      }
    },
    'excelForm.isCovered'() {
      if (this.excelForm.isCovered !== '') {
        const column = this.findObject(this.excelOption.column, "excelFile");
        column.action = `/api/oa-user/import-user?isCovered=${this.excelForm.isCovered}`;
      }
    }
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.user_add, false),
        viewBtn: this.vaildData(this.permission.user_view, false),
        delBtn: this.vaildData(this.permission.user_delete, false),
        editBtn: this.vaildData(this.permission.user_edit, false)
      };
    },

    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {

  },
  methods: {
    handleDisable(item) {
      this.$confirm("请确认是否操作?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return update({"id": item.id, status: !item.status * 1});
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
    },
    submitAddUser() {
      if (!this.addForm.userId) {
        this.$message.error("请选择用户！")
        return;
      }
      addFromGeelyUserCenter({geelyUserId: this.addForm.userId}).then(() => {
        this.addForm = JSON.parse(JSON.stringify(addForm));
        this.selectUserDialog = false;
        this.page.currentPage = 1;
        this.onLoad(this.page)
      })
    },
    // 异步搜索用户
    querySearchEnmNoAsync(queryString, cb) {
      if (queryString && queryString.trim()) {
        listGeelyUser({empNo: queryString.trim()}).then(res => {
          cb(res.data.data.records)
        })
      }
    },
    querySearchNameDisplayAsync(queryString, cb) {
      if (queryString && queryString.trim()) {
        listGeelyUser({nameDisplay: queryString.trim()}).then(res => {
          cb(res.data.data.records)
        })
      }
    },
    // 当员工选择工号
    handleEmpNoSelect(item) {
      if (this.checkUserSelect(item)) {
        this.addForm.empNo = item.nameDisplay
      }
    },
    // 选择姓名时
    handleNameDisplaySelect(item) {
      if (this.checkUserSelect(item)) {
        this.addForm.nameDisplay = item.nameDisplay
      }
    },
    checkUserSelect(item){
      this.addForm.userId = item.id
      return true
    },
    addUserFromCenter() {
      this.selectUserDialog = true;
    },
    nodeClick(data) {
      this.treeDeptId = data.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },

    submitRole() {
      const roleList = this.$refs.treeRole.getCheckedKeys().join(",");
      grant(this.ids, roleList).then(() => {
        this.roleBox = false;
        this.$message({
          type: "success",
          message: "操作成功!"
        });
        this.onLoad(this.page);
      });
    },
    rowSave(row, done, loading) {
      row.deptId = row.deptId.join(",");
      row.roleId = row.roleId.join(",");
      row.postId = row.postId.join(",");
      add(row).then(() => {
        this.initFlag = false;
        this.onLoad(this.page);
        this.$message({
          type: "success",
          message: "操作成功!"
        });
        done();
      }, error => {
        window.console.log(error);
        loading();
      });
    },

    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
    },
    searchReset() {
      this.query = {};
      this.treeDeptId = '';
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          this.$refs.crud.toggleSelection();
        });
    },
    handleReset() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择账号密码重置为123456?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return resetPassword(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          this.$refs.crud.toggleSelection();
        });
    },
    handleGrant() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.roleTreeObj = [];
      if (this.selectionList.length === 1) {
        this.roleTreeObj = this.selectionList[0].roleId.split(",");
      }
      getRoleTree().then(res => {
        this.roleGrantList = res.data.data;
        this.roleBox = true;
      });
    },
    handleLock() {
      this.$confirm("确定将选择账号解封？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          return unlock(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
    },
    handleImport() {
      this.excelBox = true;
    },
    uploadAfter(res, done, loading, column) {
      window.console.log(column);
      this.excelBox = false;
      this.refreshChange();
      done();
    },
    handleExport() {
      this.$confirm("是否导出用户数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        window.open(`/api/oa-user/export-user?${this.website.tokenHeader}=${getToken()}&account=${this.search.account}&realName=${this.search.realName}`);
      });
    },
    handleTemplate() {
      window.open(`/api/oa-user/export-template?${this.website.tokenHeader}=${getToken()}`);
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getUser(this.form.id).then(res => {
          this.form = res.data.data;
          if (this.form.hasOwnProperty("deptId")) {
            this.form.deptId = this.form.deptId.split(",");
          }
          if (this.form.hasOwnProperty("roleId")) {
            this.form.roleId = this.form.roleId.split(",");
          }
          if (this.form.hasOwnProperty("postId")) {
            this.form.postId = this.form.postId.split(",");
          }
        });
      }
      this.initFlag = true;
      done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query), this.treeDeptId).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },

    platformSizeChange(pageSize) {
      this.platformPage.pageSize = pageSize;
    },

    platformOnLoad(page, params = {}) {
      this.platformLoading = true;
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query), this.treeDeptId).then(res => {
        const data = res.data.data;
        this.platformPage.total = data.total;
        this.platformData = data.records;
        this.platformLoading = false;
        this.selectionClear();
      });
    }
  }
};
</script>

<style>
.box {
  height: 800px;
}

.el-scrollbar {
  height: 100%;
}

.box .el-scrollbar__wrap {
  overflow: scroll;
}

.search_name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.discript{
  color: gray;
  margin-bottom: 10px;
  float: right;
  text-align: right;
}
.search_addr {
  font-size: 12px;
  color: #b4b4b4;
}
</style>
