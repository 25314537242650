var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          option: _vm.option,
          "table-loading": _vm.loading,
          data: _vm.data,
          page: _vm.page
        },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "row-save": _vm.rowSave,
          "row-update": _vm.rowUpdate,
          "current-change": _vm.currentChange,
          "size-change": _vm.sizeChange,
          "refresh-change": _vm.refreshChange,
          "on-load": _vm.loadData
        },
        scopedSlots: _vm._u([
          {
            key: "avatar",
            fn: function(scope) {
              return [
                scope.row.avatar
                  ? _c(
                      "div",
                      { staticClass: "imgs-wrap" },
                      [
                        _c("el-image", {
                          key: scope.row.avatar,
                          attrs: {
                            src: scope.row.avatar,
                            "preview-src-list": [scope.row.avatar]
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            }
          },
          {
            key: "menu",
            fn: function(scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.handleDetail(scope.row)
                      }
                    }
                  },
                  [_vm._v("查看/绑定用户\n        ")]
                ),
                _c(
                  "el-button",
                  {
                    style: scope.row.status === 1 ? "color: red" : "",
                    attrs: { size: "mini", type: "text" },
                    on: {
                      click: function($event) {
                        return _vm.rowDel(scope.row)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(scope.row.status === 1 ? "禁用" : "启用") +
                        "\n        "
                    )
                  ]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.form,
          callback: function($$v) {
            _vm.form = $$v
          },
          expression: "form"
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "modal-append-to-body": false,
            visible: _vm.showDialog,
            width: "40%",
            title: "新增绑定人员",
            "before-close": _vm.handleCancel
          },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              !_vm.showDetail
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择人员" } },
                    [
                      _c("el-cascader", {
                        ref: "cascaderAddr",
                        staticStyle: { width: "50%" },
                        attrs: {
                          props: _vm.props,
                          options: _vm.deptOptions,
                          clearable: "",
                          filterable: "",
                          "before-filter": _vm.handleSearch,
                          "show-all-levels": false
                        },
                        on: {
                          change: _vm.handleChange,
                          "visible-change": _vm.handleMenuChange
                        },
                        nativeOn: {
                          input: function($event) {
                            return _vm.handleInput($event)
                          }
                        },
                        model: {
                          value: _vm.deptChoose,
                          callback: function($$v) {
                            _vm.deptChoose = $$v
                          },
                          expression: "deptChoose"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.addForm, "label-width": "150px" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "根据姓名搜索" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "90%" },
                    attrs: {
                      "fetch-suggestions": _vm.querySearchNameDisplayAsync,
                      placeholder: "根据姓名搜索"
                    },
                    on: { select: _vm.handleNameDisplaySelect },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("div", { staticClass: "search_name" }, [
                              _vm._v(_vm._s(item.nameDisplay))
                            ]),
                            _c("span", { staticClass: "search_addr" }, [
                              _vm._v(_vm._s(item.deptStr))
                            ])
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.addForm.nameDisplay,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "nameDisplay", $$v)
                      },
                      expression: "addForm.nameDisplay"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "根据工号搜索" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "90%" },
                    attrs: {
                      "fetch-suggestions": _vm.querySearchEnmNoAsync,
                      placeholder: "请输入工号"
                    },
                    on: { select: _vm.handleEmpNoSelect },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("div", { staticClass: "search_name" }, [
                              _vm._v(_vm._s(item.nameDisplay))
                            ]),
                            _c("span", { staticClass: "search_addr" }, [
                              _vm._v(_vm._s(item.deptStr))
                            ])
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.addForm.empNo,
                      callback: function($$v) {
                        _vm.$set(_vm.addForm, "empNo", $$v)
                      },
                      expression: "addForm.empNo"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "discript" }, [
            _vm._v("一次只能绑定一个用户，请不要同时使用工号和姓名添加")
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.changeData } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "modal-append-to-body": false,
            visible: _vm.showDetail,
            width: "60%",
            title: "官方身份绑定列表"
          },
          on: {
            "update:visible": function($event) {
              _vm.showDetail = $event
            }
          }
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.addNewUser($event)
                }
              }
            },
            [_vm._v("新增\n      ")]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.orgDetail, "show-header": true }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "empNo", label: "工号" }
              }),
              _c("el-table-column", {
                attrs: { prop: "username", label: "用户名" }
              }),
              _c("el-table-column", {
                attrs: { prop: "deptStr", label: "部门", width: "200" }
              }),
              _c("el-table-column", {
                attrs: { prop: "updateUser", label: "修改人" }
              }),
              _c("el-table-column", {
                attrs: { prop: "updateTime", label: "修改时间" }
              }),
              _c("el-table-column", {
                attrs: { width: "100", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "red" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.handleRemove(scope.row)
                              }
                            }
                          },
                          [_vm._v("\n              删除\n              ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }