var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          data: _vm.data,
          option: _vm.option,
          page: _vm.page,
          "table-loading": _vm.loading
        },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "search-change": _vm.searchChange,
          "search-reset": _vm.searchReset,
          "current-change": _vm.currentChange,
          "size-change": _vm.sizeChange,
          "refresh-change": _vm.refreshChange,
          "on-load": _vm.onLoad
        },
        scopedSlots: _vm._u([
          {
            key: "menu",
            fn: function(scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.updateFn(scope)
                      }
                    }
                  },
                  [_vm._v("修改")]
                ),
                _c(
                  "el-button",
                  {
                    style: scope.row.status === 1 ? "color: red;" : "",
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.rowStatus(scope)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.status === 1 ? "停用" : "启用") +
                        "\n      "
                    )
                  ]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { color: "red" },
                    attrs: { size: "small", type: "text" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.deleteFn(scope)
                      }
                    }
                  },
                  [_vm._v("删除")]
                )
              ]
            }
          },
          {
            key: "menuLeft",
            fn: function(scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.addFn(scope)
                      }
                    }
                  },
                  [_vm._v("新增")]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.formData,
          callback: function($$v) {
            _vm.formData = $$v
          },
          expression: "formData"
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            title: _vm.isDialogAdd ? "新增" : "修改",
            visible: _vm.showDialog,
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showDialog = $event
            }
          }
        },
        [
          _c("div", { staticClass: "dialogBox" }, [
            _c(
              "div",
              { staticClass: "boxItem" },
              [
                _c("p", [_vm._v("活动名称：")]),
                _c("el-input", {
                  attrs: {
                    maxlength: "24",
                    placeholder: "请输入活动名称",
                    "show-word-limit": ""
                  },
                  model: {
                    value: _vm.form.activityName,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "activityName", $$v)
                    },
                    expression: "form.activityName"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "boxItem" },
              [
                _c("p", [_vm._v("活动图片：")]),
                _c(
                  "el-upload",
                  {
                    ref: "upload",
                    staticClass: "avatar-uploader",
                    staticStyle: { display: "inline-flex" },
                    attrs: {
                      "auto-upload": false,
                      "on-change": _vm.handleChange,
                      "show-file-list": false,
                      action: "#",
                      drag: ""
                    }
                  },
                  [
                    _vm.form.imgUrl
                      ? _c("img", {
                          ref: "showImage",
                          staticClass: "avatar",
                          attrs: { src: _vm.form.imgUrl, alt: "" }
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon"
                        })
                  ]
                ),
                _c("cropper-image", {
                  attrs: { option: _vm.cropOption, visible: _vm.dialogCrop },
                  on: { finishCrop: _vm.finishCrop }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "boxItem" },
              [
                _c("p", [_vm._v("选择类型：")]),
                _c(
                  "el-radio-group",
                  {
                    staticStyle: { "margin-top": "10px" },
                    on: { change: _vm.handleChooseType },
                    model: {
                      value: _vm.form.type,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type"
                    }
                  },
                  [
                    _c("el-radio", { attrs: { label: 0 } }, [_vm._v("帖子")]),
                    _c("el-radio", { attrs: { label: 1 } }, [_vm._v("投票")]),
                    _c("el-radio", { attrs: { label: 2 } }, [_vm._v("文章")]),
                    _c("el-radio", { attrs: { label: 3 } }, [_vm._v("外链")])
                  ],
                  1
                )
              ],
              1
            ),
            _vm.form.type === 0
              ? _c(
                  "div",
                  { staticClass: "boxItem" },
                  [
                    _c("p", [_vm._v("选择帖子：")]),
                    _c(
                      "el-select",
                      {
                        attrs: { filterable: "", placeholder: "请选择" },
                        model: {
                          value: _vm.form.articleId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "articleId", $$v)
                          },
                          expression: "form.articleId"
                        }
                      },
                      _vm._l(_vm.articleOptions, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.title, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.form.type === 1
              ? _c(
                  "div",
                  { staticClass: "boxItem" },
                  [
                    _c("p", [_vm._v("选择投票：")]),
                    _c(
                      "el-select",
                      {
                        attrs: { filterable: "", placeholder: "请选择" },
                        model: {
                          value: _vm.form.voteId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "voteId", $$v)
                          },
                          expression: "form.voteId"
                        }
                      },
                      _vm._l(_vm.voteOptions, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.title, value: item.id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.form.type === 2
              ? _c(
                  "div",
                  { staticClass: "boxItem" },
                  [
                    _c("p", [_vm._v("文章内容：")]),
                    _c("editor", { ref: "editorOne" })
                  ],
                  1
                )
              : _vm._e(),
            _vm.form.type === 3
              ? _c(
                  "div",
                  { staticClass: "boxItem" },
                  [
                    _c("p", [_vm._v("外链：")]),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 4,
                        placeholder: "请输入外链"
                      },
                      model: {
                        value: _vm.form.urlContent,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "urlContent", $$v)
                        },
                        expression: "form.urlContent"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.showDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitFn } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }