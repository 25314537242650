var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c("avue-crud", {
        ref: "crud",
        attrs: {
          option: _vm.option,
          "table-loading": _vm.loading,
          data: _vm.data,
          page: _vm.page,
          search: _vm.query
        },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "row-del": _vm.rowDel,
          "search-change": _vm.searchChange,
          "search-reset": _vm.searchReset,
          "current-change": _vm.currentChange,
          "size-change": _vm.sizeChange,
          "refresh-change": _vm.refreshChange,
          "selection-change": _vm.selectionChange,
          "on-load": _vm.loadData
        },
        scopedSlots: _vm._u([
          {
            key: "imageList",
            fn: function(scope) {
              return [
                scope.row.imageList
                  ? _c(
                      "div",
                      { staticClass: "imgs-wrap" },
                      _vm._l(scope.row.imgUrlList, function(img) {
                        return _c("el-image", {
                          key: img,
                          attrs: { src: img, "preview-src-list": [img] }
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                scope.row.videoUrl
                  ? _c("div", { staticClass: "video-wrap" }, [
                      _c("video", {
                        staticClass: "video",
                        attrs: {
                          width: "60px",
                          height: "40px",
                          src: scope.row.videoUrl
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleVideoClick(scope.row.videoUrl)
                          }
                        }
                      })
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "menu",
            fn: function(scope) {
              return [
                _vm.permission.article_detail
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.rowView(scope)
                          }
                        }
                      },
                      [_vm._v("查看详情\n      ")]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.addDynamicTag(scope.row)
                      }
                    }
                  },
                  [_vm._v("\n        设置标签\n      ")]
                ),
                _vm.permission.article_top
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.rowTop(scope)
                          }
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.topSort === 0 ? "设为置顶" : "取消置顶"
                          ) + "\n      "
                        )
                      ]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.rowBottom(scope.row)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        scope.row.bottomSort === 0 ? "设为置底" : "取消置底"
                      ) + "\n      "
                    )
                  ]
                ),
                _vm.permission.article_delete && scope.row.deletedStatus === 3
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.rowRollbackDel(scope)
                          }
                        }
                      },
                      [_vm._v("\n        撤销\n      ")]
                    )
                  : _c(
                      "el-button",
                      {
                        style: scope.row.deletedStatus === 1 ? "color:red" : "",
                        attrs: {
                          type: "text",
                          size: "small",
                          disabled: scope.row.deletedStatus === 2
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.rowDel(scope)
                          }
                        }
                      },
                      [_vm._v("\n        删除\n      ")]
                    )
              ]
            }
          }
        ]),
        model: {
          value: _vm.form,
          callback: function($$v) {
            _vm.form = $$v
          },
          expression: "form"
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "帖子数据导出",
            visible: _vm.timeSelector,
            "modal-append-to-body": false,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.timeSelector = $event
            }
          }
        },
        [
          _c("div", { staticClass: "dialogBox" }, [
            _c(
              "div",
              { staticClass: "boxItem" },
              [
                _c("p", [_vm._v("时间范围：")]),
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "picker-options": _vm.pickerOptions,
                    "value-format": "timestamp",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期"
                  },
                  model: {
                    value: _vm.downloadTime,
                    callback: function($$v) {
                      _vm.downloadTime = $$v
                    },
                    expression: "downloadTime"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.timeSelector = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "download-excel",
                {
                  staticClass: "outFileBtn",
                  attrs: {
                    fields: _vm.outFiles,
                    name: "帖子管理数据.xls",
                    fetch: _vm.getAllData
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.timeSelector = false
                        }
                      }
                    },
                    [_vm._v("确认")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改帖子分类",
            visible: _vm.dialogCategoryVisible,
            "modal-append-to-body": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogCategoryVisible = $event
            }
          }
        },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择分类" },
              model: {
                value: _vm.dialogCategoryType,
                callback: function($$v) {
                  _vm.dialogCategoryType = $$v
                },
                expression: "dialogCategoryType"
              }
            },
            _vm._l(_vm.categoryOptions, function(item) {
              return _c("el-option", {
                key: item.dictKey,
                attrs: {
                  size: "medium",
                  label: item.dictValue,
                  value: item.dictKey
                }
              })
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogCategoryVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitCategoryType }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改标签",
            visible: _vm.dialogTagVisible,
            "modal-append-to-body": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTagVisible = $event
            }
          }
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { multiple: true, placeholder: "请选择标签" },
              model: {
                value: _vm.dialogTagType,
                callback: function($$v) {
                  _vm.dialogTagType = $$v
                },
                expression: "dialogTagType"
              }
            },
            _vm._l(_vm.tagOptions, function(item) {
              return _c("el-option", {
                key: item.dictKey,
                attrs: {
                  size: "medium",
                  label: item.dictValue,
                  value: item.dictKey
                }
              })
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogTagVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitTagType }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看视频",
            visible: _vm.showVideoDialog,
            "modal-append-to-body": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showVideoDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "video-dialog",
              staticStyle: { "text-align": "center" }
            },
            [
              _c("video", {
                attrs: {
                  controls: "",
                  width: "400px",
                  height: "300px",
                  src: _vm.videoUrl
                }
              })
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showVideoDialog = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }