var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "avue-crud",
        {
          ref: "crud",
          attrs: {
            option: _vm.option,
            "table-loading": _vm.loading,
            data: _vm.data,
            page: _vm.page,
            permission: _vm.permissionList,
            "before-open": _vm.beforeOpen
          },
          on: {
            "update:page": function($event) {
              _vm.page = $event
            },
            "row-update": _vm.rowUpdate,
            "row-save": _vm.rowSave,
            "row-del": _vm.rowDel,
            "search-change": _vm.searchChange,
            "search-reset": _vm.searchReset,
            "selection-change": _vm.selectionChange,
            "current-change": _vm.currentChange,
            "size-change": _vm.sizeChange,
            "refresh-change": _vm.refreshChange,
            "on-load": _vm.onLoad
          },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function(scope) {
                return [
                  _vm.userInfo.role_name.includes("admin")
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-video-play",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleDebug(scope.row)
                            }
                          }
                        },
                        [_vm._v("调试\n      ")]
                      )
                    : _vm._e(),
                  _vm.permission.sms_enable
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "text",
                            icon: "el-icon-circle-check",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handleEnable(scope.row)
                            }
                          }
                        },
                        [_vm._v("启用\n      ")]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "status",
              fn: function(ref) {
                var row = ref.row
                return [_c("el-tag", [_vm._v(_vm._s(row.statusName))])]
              }
            },
            {
              key: "category",
              fn: function(ref) {
                var row = ref.row
                return [_c("el-tag", [_vm._v(_vm._s(row.categoryName))])]
              }
            }
          ]),
          model: {
            value: _vm.form,
            callback: function($$v) {
              _vm.form = $$v
            },
            expression: "form"
          }
        },
        [
          _c(
            "template",
            { slot: "menuLeft" },
            [
              _vm.permission.sms_delete
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "danger",
                        size: "small",
                        icon: "el-icon-delete",
                        plain: ""
                      },
                      on: { click: _vm.handleDelete }
                    },
                    [_vm._v("删 除\n      ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "手机短信发送调试",
            "append-to-body": "",
            visible: _vm.box,
            width: "550px"
          },
          on: {
            "update:visible": function($event) {
              _vm.box = $event
            }
          }
        },
        [
          _c("avue-form", {
            attrs: { option: _vm.debugOption },
            on: { submit: _vm.handleSend },
            model: {
              value: _vm.debugForm,
              callback: function($$v) {
                _vm.debugForm = $$v
              },
              expression: "debugForm"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }