<template>
  <div class="commentItem">
    <div class="commentContent commonHover">
      <span class="contentText">{{username}}：<span v-html="content"></span></span>
      <i v-if="deletedStatus === 1" class="el-icon-delete" @click="handleDelete(commentId, 'comment')"/>
    </div>
    <div class="commentList">
      <div v-for="(item, index) in commentList" :key="index" class="commentContent">
        <div class="contentText">
          <span>{{item.username}}</span>
          <span v-if="item.username"> 回复 {{item.username}}</span>
          <span v-html="item.content"></span>
        </div>
        <i v-if="item.deletedStatus === 1" class="el-icon-delete" @click="handleDelete(item.commentId, 'reply', index)"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "commentItem",
  props: {
    username: String,
    content: String,
    commentList: {
      type: Array,
      default: () => []
    },
    deletedStatus: Number,
    commentId: String,
    id: Number
  },
  methods: {
    handleDelete(commentId, type, replyIndex) {
      this.$emit('click', {commentId, type, replyIndex})
    }
  }
}
</script>

<style lang="scss" scoped>
.commentItem {
  margin-bottom: 10px;

  i {
    cursor: pointer;
  }

  .commentContent {

    display: flex;
    justify-content: space-between;
    .contentText {
      word-break: break-all;
    }
  }
  .commonHover:hover{
    background-color: #1e9fff;
  }
  .commentList {
    :hover{
      background-color: #00a680;
    }
    margin-left: 30px;

    .commentContent {
      margin-top: 4px;
    }
  }
}
</style>
