<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      :page.sync="page"
      v-model="form"
      @search-change="searchChange"
      @search-reset="searchReset"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft">
        <el-button type="primary" @click="downloadExcel">导出</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>
import { reportList, replyExcel } from "../../api/article";
export default {
  data() {
    return {
      form: {},
      data: [],
      loading: true,
      query: {
        phone: ''
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      option: {
        title: "回复报表",
        addBtn: false,
        searchLabelWidth: 120,
        searchShow: true,
        searchSpan: 8,
        menu: false,
        searchMenuSpan: 4,
        index: true,
        indexLabel: "序号",
        labelWidth: 200,
        menuWidth: 200,
        menuAlign: "center",
        align: "center",
        column: [
          {
            label: "姓名",
            prop: "userName",
            search: true,
          },
          {
            label: "手机号",
            prop: "phone",
            search: true,
          },
          {
            label: "被@数（次）",
            prop: "count",
          },
          {
            label: "回复数（次）",
            prop: "replaySum",
          },
          {
            label: "回复率（%）",
            prop: "responseRate",
          },
          {
            label: "平均回复时长（h）",
            prop: "averageTime",
          },
          {
            label: "平均回复数（条）",
            prop: "averageNumberResponse",
          },
          {
            label: "最后回复时间",
            prop: "endTime",
          },
        ],
      },
    };
  },
  methods: {
    //onload方法
    onLoad(page, params = {}) {
      console.log(params,this.query);
      this.loading = false;
      reportList({
        pageNum: page.currentPage,
        pageSize: page.pageSize,
        keyword: this.query.userName ? this.query.userName : this.query.phone,
        ...Object.assign(params, this.query),
      }).then((res) => {
        // console.log(res);
        this.loading = false;
        this.page.total = res.data.data.total;
        this.data = res.data.data.records.map((item) => {
          return {
            ...item,
          };
        });
      });
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    async downloadExcel() {
      const res = await replyExcel()
      const blob = res.data
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = (e) => {
        const a = document.createElement('a');
        a.download = `回复报表.xls`;
        a.href = e.target.result;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
